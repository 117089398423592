import React from 'react';
import Item from './Item';
import {TableBody, TableCell, TableRow} from '@mui/material';

function Body(props) {
	return (
		<TableBody>
			{props.isLoading ? (
				<TableRow>
					<TableCell>Loading...</TableCell>
				</TableRow>
			) : props.data.length === 0 ? (
				<TableRow>
					<TableCell>{''}</TableCell>
				</TableRow>
			) : (
				props.data.map((value, index) => (
					<Item
						key={index}
						value={value}
						index={index}
						setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
						data={props.data}
						setData={props.setData}
						userType={props.userType}
					/>
				))
			)}
		</TableBody>
	);
}

export default Body;
