import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'

function Header() {
    return (
        <TableHead>
            <TableRow>
                <TableCell align={'left'}>
                    <TableSortLabel>Account Name</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Wallet Address</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>USDBBalance</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>AML/KYC Status</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Investor Status</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Faucet</TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default Header
