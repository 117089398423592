import React from 'react';
import Item from './Item';
import {TableBody, TableRow, TableCell} from '@mui/material';

function Body(props) {
	return (
		<TableBody>
			{props.isLoading ? (
				<TableRow>
					<TableCell>Loading...</TableCell>
				</TableRow>
			) : props.data.length === 0 ? (
				<TableRow>
					<TableCell>{''}</TableCell>
				</TableRow>
			) : (
				props.data.map((value, index) => (
					<Item key={index} value={value} userType={props.userType} />
				))
			)}
		</TableBody>
	);
}

export default Body;
