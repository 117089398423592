import React, {useState} from 'react'
import {
    TablePagination
} from '@mui/material';

function Pagination(props) {

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0)
    }

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e) => setPage(e.target.value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
        >
            Pagination
        </TablePagination>
    )
}

export default Pagination