import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, ListItem } from '@mui/material'
import React from 'react'

function NavItem(props) {
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                px: 2,
            }}
        >
            <Link to={props.href} sx={{textDecoration: 'none'}}>
                <Button
                    startIcon={props.icon}
                    disableRipple
                    sx={{
                        borderRadius: 1,
                        color: 'primary.main',
                        fontWeight: 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        textDecoration: 'none',
                        '& .MuiButton-startIcon': {
                            color: 'primary.main',
                        },
                        '&:hover': {},
                    }}
                >
                    <Box sx={{ flexGrow: 1, textDecoration: 'none' }}>{props.title}</Box>
                </Button>
            </Link>
        </ListItem>
    )
}

export default NavItem
