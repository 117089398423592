import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import Logo from '../../assets/logo.png'
import ConnectWallet from './ConnectWallet'
import '../../styles/login.css'
import { useNavigate } from 'react-router-dom'

function Login(props) {
    const [isOpen, setIsOpen] = useState(false)

    let navigate = useNavigate()

    useEffect(() => {
        if (window.location.pathname !== '/') {
            navigate(`/`)
        }
    }, [])

    return (
        <>
            <Box
                id="login"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    minHeight: '100%',
                }}
            >
                <Container maxWidth="sm">
                    <Box component="img" sx={{ height: 64, mb: 4 }} alt="Your logo." src={Logo} />

                    <div style={{ padding: '15px', backgroundColor: '#FFFFFF' }}>
                        <Box sx={{ my: 3 }}>
                            <Typography color="textPrimary" variant="h4">
                                Login
                            </Typography>
                            <Typography color="textSecondary" gutterBottom variant="body2">
                                Login in on the internal platform
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                pb: 1,
                                pt: 3,
                            }}
                        ></Box>
                        <Box sx={{ py: 2 }}>
                            <ConnectWallet
                                setIsLoggedIn={props.setIsLoggedIn}
                                setUserType={props.setUserType}
                                setIsOpen={setIsOpen}
                                isOpen={isOpen}
                                setCurrentUser={props.setCurrentUser}
                                setAllUsers={props.setAllUsers}
                            />
                        </Box>
                    </div>
                </Container>

                {/*
                    isOpen &&
                    <ConnectWallet
                        setIsLoggedIn={props.setIsLoggedIn}
                        setUserType={props.setUserType}   
                        setIsOpen={setIsOpen} 
                        isOpen={isOpen}
                        setCurrentUser={props.setCurrentUser}
                    />
            */}
            </Box>
        </>
    )
}

export default Login
