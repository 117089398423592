import React, {useEffect, useState} from 'react';
import {TableRow, TableCell, Button, Tooltip} from '@mui/material';
import {format} from 'date-fns';
import {Link} from 'react-router-dom';
import TOKEN from '../../../ABI/TOKEN.json';
import {ethers} from 'ethers';
import {useWeb3Data} from '../../../context/Web3Context';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import RIGHTS from '../../../ABI/RIGHTS.json';

function Item(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	const [isApproved, setIsApproved] = useState(false);

	const handleApprove = async () => {
		const signer = provider.getUncheckedSigner();
		let contract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);
		let tokenContract = new ethers.Contract(
			process.env.REACT_APP_TOKEN_ADDRESS,
			TOKEN,
			signer
		);
		let rTokenAddr = await contract.rightsToken();

		console.log(
			`${wallet?.accounts[0]?.address} is approving, ${rTokenAddr} to spend ${props.value.loanObligation}`
		);

		// console.log(await tokenContract.totalSupply());

		try {
			const {hash} = await tokenContract
				.approve(
					rTokenAddr,
					ethers.utils.parseEther(props.value.loanObligation)
				)
				.then((e) => {
					console.log(e);

					return e;
				})
				.catch((err) => {
					console.log(err);
				});

			await txNotifications(hash, 3);
		} catch (err) {
			console.log(err);
		}
	};

	const lendFunds = async () => {
		const signer = provider.getUncheckedSigner();
		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);

		try {
			let addr = await loanContract.rightsToken().then((addr) => {
				console.log(addr);
				return addr;
			});
			let rightsContract = new ethers.Contract(addr, RIGHTS, signer);

			const {hash} = await rightsContract
				.lendFunds()
				.then((e) => {
					console.log(e);
					return e;
				})
				.catch((err) => {
					console.log(err);
				});

			await txNotifications(hash, 2);
		} catch (err) {
			console.log(err);
		}
	};

	const handleSettleDebt = async () => {
		console.log(props.value.loanState);
		const signer = provider.getUncheckedSigner();
		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);
		loanContract
			.rightsToken()
			.then(async (addr) => {
				// console.log(addr)
				let rightsContract = new ethers.Contract(addr, RIGHTS, signer);
				console.log('addr');
				console.log(addr);
				console.log(props.value.contract);

				const {hash} = await rightsContract
					.settleDebt()
					.then((e) => {
						console.log(e);
						return e;
					})
					.catch((err) => {
						console.log(err);
					});
				await txNotifications(hash, 2);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteLoan = async () => {
		console.log(props.value.loanState);
		const signer = provider.getUncheckedSigner();
		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);
		try {
			const {hash} = await loanContract
				.setLoanState(5)
				.then((e) => {
					console.log(e);
					return e;
				})
				.catch((err) => {
					console.log(err);
				});

			await txNotifications(hash, 2);
		} catch (err) {
			console.log(err);
		}
	};

	const txNotifications = (hash, value) => {
		const {emitter} = notify.hash(hash);

		emitter.on('txPool', (transaction) => {
			return {
				onclick: () =>
					window.open(`https://goerli.etherscan.io/tx/${transaction.hash}`)
			};
		});
		emitter.on('txSent', console.log);
		emitter.on('txConfirmed', () => {
			console.log('loanstate: ', props.value.loanState);
			console.log('isApproved: ', isApproved);
			console.log('borrowerAddress: ', borrowerAddress);
			if (value === 2) {
				let newData = [...props.data];
				newData[props.index].loanState++;
				props.setData(newData);
			}
			if (value === 3) {
				let newData = [...props.data];
				props.setData(newData);
				setIsApproved(true);
			}
		});
		emitter.on('txSpeedUp', console.log);
		emitter.on('txCancel', console.log);
		emitter.on('txFailed', console.log);
	};

	const [borrowerAddress, setBorrowerAddress] = useState(null);

	const handleAllowance = async () => {
		if (provider) {
			let contract = new ethers.Contract(
				process.env.REACT_APP_TOKEN_ADDRESS,
				TOKEN,
				provider
			);
			let loanContract = new ethers.Contract(
				props.value.contract,
				LOANFAUCET,
				provider
			);
			let rTokenAddr = await loanContract.rightsToken();

			await loanContract
				.borrowerAddress()
				.then((addr) => {
					setBorrowerAddress(addr.toLowerCase());

					// console.log(addr.toLowerCase())
					// console.log(wallet?.accounts[0]?.address)
				})
				.catch((err) => {
					console.log(`err for`, err);
				});

			await contract
				.allowance(wallet?.accounts[0]?.address, rTokenAddr)
				.then((status) => {
					console.log('allowance', parseInt(status));
					if (parseInt(status) > 0) {
						setIsApproved(true);
						console.log('approved', rTokenAddr);
						console.log('approved for ', parseInt(status));
					} else {
						setIsApproved(false);
					}
				})
				.catch((err) => {
					console.log('err', err);
				});
		}
	};

	useEffect(() => {
		handleAllowance();
	}, [provider]);

	return (
		<TableRow hover>
			<TableCell align='left'>
				{/* <Link to={'/loan-details'} onClick={() => props.setSelectedLoanDetailsItem(props.data[props.index])}> */}
				{props.value.instrumentName}
				{/* </Link> */}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.cusip}
			</TableCell>
			<TableCell align='center' sx={{p: 1}}>
				{props.value.issuerName}
			</TableCell>
			<TableCell align='center' sx={{p: 1}}>
				{props.value.loanSize}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.price}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.startDate}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.maturityDate}
			</TableCell>

			<TableCell sx={{p: 1}} align='center'>
				{props.value.loanState === 2 && props.userType === 1 && (
					<Button
						color='primary'
						variant='contained'
						sx={{my: 1}}
						onClick={() => lendFunds()}
						disabled={props.userType === 0}
					>
						Lend
					</Button>
				)}
				{props.value.loanState === 3 &&
					isApproved === false &&
					borrowerAddress === wallet?.accounts[0]?.address && (
						<Button
							color='primary'
							variant='contained'
							sx={{my: 1}}
							onClick={() => handleApprove()}
						>
							Approve
						</Button>
					)}
				{props.value.loanState === 3 &&
					isApproved === true &&
					borrowerAddress === wallet?.accounts[0]?.address && (
						<Button
							color='primary'
							variant='contained'
							sx={{my: 1}}
							onClick={() => handleSettleDebt()}
						>
							Settle
						</Button>
					)}
				{props.value.loanState === 3 &&
					isApproved === false &&
					borrowerAddress !== wallet?.accounts[0]?.address && (
						<Tooltip title="You don't have permission to call this.">
							<Button
								color='primary'
								variant='contained'
								sx={{my: 1}}
								align='center'
								disabled
							>
								Payback
							</Button>
						</Tooltip>
					)}
				{props.value.loanState === 4 && (
					<Button
						color='error'
						variant='contained'
						sx={{my: 1}}
						onClick={() => deleteLoan()}
					>
						Delete
					</Button>
				)}
			</TableCell>
		</TableRow>
	);
}

export default Item;
