import React, {useState} from 'react';
import {Button} from '@mui/material';
import {useWeb3Data} from '../../context/Web3Context';
import {ethers} from 'ethers';
import LOAN from '../../ABI/LOAN.json';
import Moment from 'moment';

function Submit(props) {
	const {notify, provider} = useWeb3Data();
	const [isComplete, setIsComplete] = useState(false);

	const handleLoanRequest = async () => {
		const signer = provider.getUncheckedSigner();
		let contract = new ethers.Contract(
			process.env.REACT_APP_LOAN_ADDRESS,
			LOAN,
			signer
		);

		console.log('initial start date', props.startDate);
		console.log('initial maturity date', props.maturityDate);

		let newStartDate = Moment(props.startDate).format('MM/DD/YYYY');
		let newMaturityDate = Moment(props.maturityDate).format('MM/DD/YYYY');

		console.log('new start date', newStartDate);
		console.log('submitted maturity date', props.maturityDate);
		console.log('new maturity date', newMaturityDate);

		let numArr = props.quantity.split('');
		for (let i = 0; i < numArr.length; i++) {
			if (numArr[i] === ',') {
				numArr.splice(i, 1);
			}
		}
		let num = numArr.join('');

		let loanRequest = parseFloat(num).toFixed(3);

		let rate = parseFloat(props.rate).toFixed(3);
		let newRate = ((rate / 100) * loanRequest).toFixed(3);

		loanRequest = loanRequest.toString();
		rate = rate.toString();
		newRate = newRate.toString();

		console.log(
			'loan params',
			newStartDate,
			newMaturityDate,
			loanRequest,
			rate,
			props.term,
			process.env.REACT_APP_TOKEN_ADDRESS,
			props.minFill
		);

		try {
			const {hash} = await contract.estimateGas
				.createNewLoan(
					newStartDate,
					newMaturityDate,
					ethers.utils.parseEther(loanRequest),
					ethers.utils.parseEther(newRate),
					props.term,
					process.env.REACT_APP_TOKEN_ADDRESS,
					props.minFill
				)
				.then(async (suggestedGas) => {
					let gasLimit = parseInt(1.5 * suggestedGas);

					return await contract.createNewLoan(
						newStartDate,
						newMaturityDate,
						ethers.utils.parseEther(loanRequest),
						ethers.utils.parseEther(newRate),
						props.term,
						process.env.REACT_APP_TOKEN_ADDRESS,
						props.minFill,
						{
							gasLimit
						}
					);
				})
				.catch((err) => {
					console.log(err);

					notify.notification({
						eventCode: 'none',
						type: 'error',
						message: JSON.stringify(err.message),
						autoDismiss: 5000
					});
				});

			txNotifications(hash);
		} catch (err) {
			console.log(err);
		}
	};

	const txNotifications = (hash) => {
		const {emitter} = notify.hash(hash);

		emitter.on('txPool', (transaction) => {
			return {
				onclick: () =>
					window.open(`https://goerli.etherscan.io/tx/${transaction.hash}`)
			};
		});
		emitter.on('txSent', console.log);
		emitter.on('txConfirmed', (val) => {
			console.log(val);
			setIsComplete(true);
		});
		emitter.on('txSpeedUp', console.log);
		emitter.on('txCancel', console.log);
		emitter.on('txFailed', console.log);
	};

	return (
		<div>
			{!isComplete ? (
				<Button
					color='primary'
					variant='contained'
					onClick={() => handleLoanRequest()}
				>
					Issue Loan Request
				</Button>
			) : (
				<Button color='primary' variant='contained' disabled={true}>
					Issue Loan Request Submitted
				</Button>
			)}
		</div>
	);
}

export default Submit;
