import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
import {initWeb3Onboard, initNotify} from '../onboard/services';
import {useConnectWallet, useSetChain, useWallets} from '@web3-onboard/react';
import {ethers} from 'ethers';

const Web3DataContext = createContext();

export const useWeb3Data = () => {
	return useContext(Web3DataContext);
};

export const Web3Provider = ({value, children}) => {
	//Hooks
	const connectedWallets = useWallets(); //Hook that allows you to track the state of all the currently connected wallets
	/**
	 *  Hook  state of the connection status and the wallet that is connected.
	 *
	 *  {wallet}          the wallet that has been connected or null if not yet connected
	 *  {connecting}      boolean indicating if connection is in progress
	 *  {connect}         function to call to initiate user to connect wallet
	 *  {disconnect}      function to call to with wallet<DisconnectOptions> to disconnect wallet
	 */
	const [{wallet, connecting}, connect, disconnect] = useConnectWallet();

	/**
	 *  Set the chain of a user's connected wallet, keep track of the current chain the user is connected to
	 *
	 *  {chains}          the list of chains that web3-onboard was initialized with
	 *  {connectedChain}  the current chain the user's wallet is connected to
	 *  {settingChain}    boolean indicating if the chain is in the process of being set
	 *  {setChain}        function to call to initiate user to switch chains in their wallet
	 */
	const [{chains, connectedChain, settingChain}, setChain] = useSetChain();

	//State
	const [web3Onboard, setWeb3Onboard] = useState(null);
	const [notify, setNotify] = useState(null);
	const [provider, setProvider] = useState(null);
	let tempProvider;

	// useEffect(() => {
	//     window.location.reload()
	// }, [connecting])

	useEffect(() => {
		setWeb3Onboard(initWeb3Onboard);

		setNotify(initNotify());
	}, []);

	useEffect(() => {
		if (!connectedWallets.length) return;

		const connectedWalletsLabelArray = connectedWallets.map(({label}) => label);
		window.localStorage.setItem(
			'connectedWallets',
			JSON.stringify(connectedWalletsLabelArray)
		);
	}, [connectedWallets]);

	useEffect(() => {
		if (!wallet?.provider) {
			tempProvider = null;
		} else {
			tempProvider = new ethers.providers.Web3Provider(wallet.provider, 'any');
			setProvider(tempProvider);
		}
	}, [wallet]);

	useEffect(() => {
		const previouslyConnectedWallets = JSON.parse(
			window.localStorage.getItem('connectedWallets')
		);

		if (previouslyConnectedWallets?.length) {
			async function setWalletFromLocalStorage() {
				await connect({autoSelect: previouslyConnectedWallets[0]});
			}
			setWalletFromLocalStorage();
		}
	}, [connect]);

	const providerValue = useMemo(
		() => ({
			web3Onboard,
			notify,
			connectedWallets,
			wallet,
			connecting,
			connect,
			disconnect,
			provider,
			connectedChain,
			setChain
		}),
		[
			web3Onboard,
			notify,
			connectedWallets,
			wallet,
			connecting,
			connect,
			disconnect,
			provider,
			connectedChain,
			setChain
		]
	);

	return (
		<Web3DataContext.Provider value={providerValue}>
			{children}
		</Web3DataContext.Provider>
	);
};

export default Web3Provider;
