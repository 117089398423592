import React, {useEffect, useState} from 'react';
import {TableRow, TableCell, Button, Tooltip} from '@mui/material';
import {format} from 'date-fns';
import {Link} from 'react-router-dom';
import {ethers} from 'ethers';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import {useWeb3Data} from '../../../context/Web3Context';
import Moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function Item(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	// console.log(props.data)

	const handleEvalute = async () => {
		const signer = provider.getUncheckedSigner();

		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);

		try {
			const {hash} = await loanContract.estimateGas
				.evalBids()
				.then(async (suggestedGas) => {
					let gasLimit = parseInt(1.5 * suggestedGas);
					return await loanContract.evalBids({gasLimit});
				})
				.catch((err) => {
					console.log(err);
				});

			txNotifications(hash);
		} catch (err) {
			console.log(err);
			notify.notification({
				eventCode: 'none',
				type: 'error',
				message: 'Auction Failed. Delete Loan.',
				autoDismiss: 8000
			});
		}
	};

	const txNotifications = (hash) => {
		const {emitter} = notify.hash(hash);

		emitter.on('txPool', (transaction) => {
			return {
				onclick: () =>
					window.open(`https://goerli.etherscan.io/tx/${transaction.hash}`)
			};
		});
		emitter.on('txSent', console.log);
		emitter.on('txConfirmed', () => {
			props.setRefreshAll(!props.refreshAll); //error check me
		});
		emitter.on('txSpeedUp', console.log);
		emitter.on('txCancel', console.log);
		emitter.on('txFailed', console.log);
	};

	const [auctionState, setAuctionState] = useState(0);

	const getAuctionStatus = async () => {
		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			provider
		);
		await loanContract
			.loanObligation()
			.then((status) => {
				// console.log(parseInt(status));
				setAuctionState(parseInt(status));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteLoan = async () => {
		const signer = provider.getUncheckedSigner();

		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);

		try {
			const {hash} = await loanContract.estimateGas
				.setLoanState(5)
				.then(async (suggestedGas) => {
					let gasLimit = parseInt(1.5 * suggestedGas);
					return await loanContract.setLoanState(5, {gasLimit});
				})
				.catch((err) => {
					console.log(err);
				});

			txNotifications(hash);
		} catch (err) {
			console.log(err);
			notify.notification({
				eventCode: 'none',
				type: 'error',
				message: 'Auction Failed. Delete Loan.',
				autoDismiss: 8000
			});
		}
	};

	useEffect(() => {
		getAuctionStatus();
	}, []);

	let url = `https://goerli.etherscan.io/address/${props.value.contract}`;

	return (
		<TableRow hover>
			<TableCell align='left' sx={{p: 1}}>
				{/* <Link to={'/loan-details'} onClick={() => props.setSelectedLoanDetailsItem(props.data[props.index])}> */}
				{props.value.instrumentName}
				{/* </Link> */}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.cusip}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.issuerName}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.loanSize}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.price}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value?.startDate}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.maturityDate}
			</TableCell>
			{/* {props.userType === 0 && (
                <TableCell align="left" sx={{ p: 1 }}>
                    <Tooltip title="Only Admin Function" placement="right">
                        <Button
                            style={{
                                backgroundColor: '#a6a6a6',
                                color: '#121828',
                            }}
                            variant="contained"
                            type="submit"
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                </TableCell>
            )} */}
			{props.userType === 1 && (
				<TableCell
					align='left'
					sx={{
						p: 1,
						display: 'flex'
					}}
				>
					<Button
						color='primary'
						variant='contained'
						type='submit'
						onClick={() => handleEvalute()}
						sx={{
							p: '3px',
							borderRadius: '20px',
							width: '30px',
							minWidth: '30px',
							marginRight: '15px'
						}}
					>
						{auctionState === 0 ? <CheckIcon /> : 'Completed'}
					</Button>
					<Button
						color='error'
						variant='contained'
						type='submit'
						onClick={() => deleteLoan()}
						sx={{
							p: '3px',
							borderRadius: '20px',
							width: '30px',
							minWidth: '30px'
						}}
					>
						{auctionState === 0 ? <DeleteForeverIcon /> : 'Completed'}
					</Button>
				</TableCell>
			)}
		</TableRow>
	);
}

export default Item;
