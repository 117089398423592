import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import DashboardNavbar from './DashboardNavbar'
import DebtInstruments from './DebtInstruments/DebtInstruments'
import DebtInvestments from './DebtInvestments/DebtInvestments'
import LoanBids from './LoanBids/LoanBids'
import LoanOffers from './LoanOffers/LoanOffers'
import UserPortfolio from './UserPortfolio/UserPortfolio'
import { Box, Container } from '@mui/material'

function Dashboard(props) {
    const [isActiveLoansLoading, setIsActiveLoansLoading] = useState(true)

    return (
        <>
            <Navbar setIsLoggedIn={props.setIsLoggedIn} userType={props.userType} refreshAll={props.refreshAll} setRefreshAll={props.setRefreshAll} />
            <Container fixed>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 8,
                    }}
                >
                    {props.userType === 0 && <UserPortfolio currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} />}
                    <DebtInstruments
                        setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
                        allUsers={props.allUsers}
                        userType={props.userType}
                        setActiveLoanInvestments={props.setActiveLoanInvestments}
                        activeLoanInvestments={props.activeLoanInvestments}
                        refreshAll={props.refreshAll}
                        isLoading={isActiveLoansLoading}
                        setIsLoading={setIsActiveLoansLoading}
                    />
                    <DebtInvestments
                        setActiveLoanInvestments={props.setActiveLoanInvestments}
                        activeLoanInvestments={props.activeLoanInvestments}
                        allUsers={props.allUsers}
                        userType={props.userType}
                        refreshAll={props.refreshAll}
                        isLoading={isActiveLoansLoading}
                        setIsLoading={setIsActiveLoansLoading}
                        // setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
                    />
                    <LoanOffers
                        userType={props.userType}
                        setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
                        allUsers={props.allUsers}
                        refreshAll={props.refreshAll}
                        setRefreshAll={props.setRefreshAll}
                    />
                    <LoanBids
                        userType={props.userType}
                        allUsers={props.allUsers}
                        refreshAll={props.refreshAll}
                        // setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
                    />
                </Box>
            </Container>
        </>
    )
}

export default Dashboard
