import Notify from 'bnc-notify';

import blocknativeLogo from '../icons/OneRiverLeft.svg';
import blocknativeIcon from '../icons/OneRiverLeft.svg';

import {init} from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import walletLinkModule from '@web3-onboard/walletlink';

// Replace with your DApp's Infura ID
const INFURA_ID = process.env.REACT_APP_INFURA_ID;
const networkId = parseInt(process.env.REACT_APP_NETWORK);
const dappId = process.env.REACT_APP_BLOCKNATIVE;

const injected = injectedModule(); //metamask
const walletLink = walletLinkModule(); //coinbase
const walletConnect = walletConnectModule(); //wallet connect QR

export const initWeb3Onboard = init({
	wallets: [injected, walletLink, walletConnect],
	accountCenter: {
		desktop: {
			enabled: false
		}
	},
	chains: [
		{
			id: '0x1',
			token: 'ETH',
			label: 'Ethereum',
			rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`
		},
		{
			id: '0x4',
			token: 'rETH',
			label: 'goerli',
			rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
		},
		{
			id: '0x5',
			token: 'gETH',
			label: 'goerli',
			rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
		}
	],
	appMetadata: {
		name: 'OR Protocol',
		icon: blocknativeIcon,
		logo: blocknativeLogo,
		description: 'OneRiver Asset Management Dapp',
		recommendedInjectedWallets: [
			{name: 'Coinbase', url: 'https://wallet.coinbase.com/'},
			{name: 'MetaMask', url: 'https://metamask.io'}
		],
		agreement: {
			version: '1.0.0',
			termsUrl: 'https://www.blocknative.com/terms-conditions',
			privacyUrl: 'https://www.blocknative.com/privacy-policy'
		},
		explore:
			'https://goerli.etherscan.io/address/0xbafe62bb9fd37fde6429ddb0a8e0be2658534ebc#code'
	},
	i18n: {
		en: {
			connect: {
				selectingWallet: {
					sidebar: {
						// heading: 'Select Wallet',
						// subheading: 'Select a wallet to connect to the network',
						// paragraph: 'You can connect to any of the following wallets',
					}
				}
			}
		}
	}
});

export function initNotify() {
	const notify = Notify;
	return notify({
		dappId,
		networkId,
		darkMode: true,
		onerror: (error) => console.log(`Notify error: ${error.message}`)
	});
}
