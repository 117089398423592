import React, {useEffect, useState} from 'react';
import {TableRow, TableCell, Input, Button, Tooltip} from '@mui/material';
import {format} from 'date-fns';
import {useWeb3Data} from '../../../context/Web3Context';
import {ethers} from 'ethers';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import TOKEN from '../../../ABI/TOKEN.json';

function Item(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	const [isApproved, setIsApproved] = useState(false);
	const [alreadySubmmited, setAlreadySubmmited] = useState(false);

	const handleApprove = async () => {
		const signer = provider.getUncheckedSigner();
		let contract = new ethers.Contract(
			process.env.REACT_APP_TOKEN_ADDRESS,
			TOKEN,
			signer
		);

		try {
			const {hash} = await contract
				.approve(
					props.value.contract,
					ethers.utils.parseEther('100000000000.0')
				)
				.catch((err) => {
					console.log(err);
				});

			await txNotifications(hash, 1);
		} catch (err) {
			console.log(err);
		}
	};

	const handleInput = (value, name, index) => {
		if (name === 'commitment') {
			if (addComa(value) !== false) {
				value = addComa(value);
				let newData = [...props.data];
				newData[index][name] = value;
				props.setData(newData);
			}
		} else {
			let newData = [...props.data];
			newData[index][name] = value;
			props.setData(newData);
		}
	};

	function addComa(value) {
		if (value.length < 2) {
			if ((value.charAt(0) >= '0' && value.charAt(0) <= '9') || value == '') {
				return value;
			} else {
				return false;
			}
		} else {
			let numArr = value.split('');
			for (let i = 0; i < numArr.length; i++) {
				if (numArr[i] === ',') {
					numArr.splice(i, 1);
				}
			}
			let num = parseInt(numArr.join(''));
			return num.toLocaleString();
		}
	}

	function removeComa(value) {
		let numArr = value.split('');
		for (let i = 0; i < numArr.length; i++) {
			if (numArr[i] === ',') {
				numArr.splice(i, 1);
			}
		}
		return numArr.join('');
	}

	const [rateCheck, setRateCheck] = useState(0);

	const handleSubmit = async (index) => {
		const signer = provider.getUncheckedSigner();
		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);

		let newRate = ((props.value.maxPrice / 100) * props.value.loanSize).toFixed(
			2
		);
		setRateCheck(newRate);

		try {
			let newCommitment = removeComa(props.value.commitment);

			console.log('newRate', newRate);
			console.log('props.value.commitment', parseInt(newCommitment));
			console.log(
				ethers.utils.parseEther(newCommitment),
				ethers.utils.parseEther(newRate)
			);

			const {hash} = await loanContract.estimateGas
				.enterBid(
					ethers.utils.parseEther(newCommitment),
					ethers.utils.parseEther(newRate)
				)
				.then(async (suggestedGas) => {
					let gasLimit = parseInt(1.5 * suggestedGas);
					console.log('gasLimit', gasLimit);
					return await loanContract.enterBid(
						ethers.utils.parseEther(newCommitment),
						ethers.utils.parseEther(newRate),
						{gasLimit}
					);
				})
				.catch((err) => {
					console.log(JSON.stringify(err.error.message));

					notify.notification({
						eventCode: 'none',
						type: 'error',
						message: JSON.stringify(err.error.message),
						autoDismiss: 8000
					});
				});

			await txNotifications(hash, 2);
		} catch (err) {
			console.log(err);
			notify.notification({
				eventCode: 'none',
				type: 'error',
				message: JSON.stringify(err.message),
				autoDismiss: 8000
			});
		}
	};

	const txNotifications = (hash, state) => {
		const {emitter} = notify.hash(hash);

		emitter.on('txPool', (transaction) => {
			return {
				onclick: () =>
					window.open(`https://goerli.etherscan.io/tx/${transaction.hash}`)
			};
		});
		emitter.on('txSent', console.log);
		emitter.on('txConfirmed', () => {
			if (state === 1) {
				setIsApproved(true);
			} else {
				setAlreadySubmmited(true);
			}
		});
		emitter.on('txSpeedUp', console.log);
		emitter.on('txCancel', console.log);
		emitter.on('txFailed', console.log);
	};

	let url = `https://goerli.etherscan.io/address/${props.value.contract}`;

	const handleAllowance = async () => {
		if (provider) {
			let contract = new ethers.Contract(
				process.env.REACT_APP_TOKEN_ADDRESS,
				TOKEN,
				provider
			);
			let loanContract = new ethers.Contract(
				props.value.contract,
				LOANFAUCET,
				provider
			);

			await loanContract.getLiveBids().then((bids) => {
				for (let i = 0; i < bids.length; i++) {
					if (bids[i].toLowerCase() === wallet?.accounts[0]?.address) {
						setAlreadySubmmited(true);
					}
				}
			});

			await contract
				.allowance(wallet?.accounts[0]?.address, props.value.contract)
				.then((amount) => {
					if (parseInt(amount) > 0) {
						// console.log('address: ', wallet?.accounts[0]?.address, ' approved for ', parseInt(amount))

						setIsApproved(true);
						// console.log('approved', props.value.contract)
					} else {
						console.log(
							'address: ',
							wallet?.accounts[0]?.address,
							' not approved'
						);
						setIsApproved(false);
					}
				})
				.catch((err) => {
					console.log('err', err);
				});
		}
	};

	useEffect(() => {
		handleAllowance();
	}, [provider]);

	return (
		<TableRow hover>
			<TableCell align='left' sx={{p: 1}}>
				<a href={url} target='_blank'>
					{props.value.instrumentName}
				</a>
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.cusip}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.issuerName}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				<Tooltip
					title={
						!isApproved
							? 'Approve Tokens First'
							: 'The Amount You Want To Lend Out'
					}
					arrow
				>
					<Input
						value={props.value.commitment}
						onChange={(e) =>
							handleInput(e.target.value, 'commitment', props.index)
						}
						disabled={!isApproved || alreadySubmmited}
					/>
				</Tooltip>
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				<Tooltip
					title={
						!isApproved
							? 'Approve Tokens First'
							: 'The Price At Which You Want To Lend'
					}
					arrow
				>
					<Input
						value={props.value.maxPrice}
						onChange={(e) =>
							handleInput(e.target.value, 'maxPrice', props.index)
						}
						disabled={!isApproved || alreadySubmmited}
					/>
				</Tooltip>
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{ethers.utils.commify(props.value.loanSize)}
			</TableCell>
			<TableCell align='left' sx={{p: 1}}>
				{props.value.startDate}
			</TableCell>
			<TableCell align='center' sx={{p: 1}}>
				{props.value.maturityDate}
			</TableCell>
			<TableCell sx={{p: 1}} align='center'>
				{alreadySubmmited ? (
					<Button color='primary' variant='contained' sx={{my: 0}} disabled>
						Submitted
					</Button>
				) : isApproved ? (
					<Button
						color='primary'
						variant='contained'
						onClick={() => handleSubmit(props.index)}
						sx={{my: 0}}
						disabled={isNaN(parseInt(props.value.maxPrice))}
					>
						Submit
					</Button>
				) : (
					<Button
						color='primary'
						variant='contained'
						onClick={() => handleApprove()}
						sx={{my: 0}}
					>
						Approve
					</Button>
				)}
			</TableCell>
		</TableRow>
	);
}

export default Item;
