import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { Box, Container, Typography } from '@mui/material'
import Body from './Body'

function LoanRequest(props) {
    const [quantity, setQuantity] = useState(0)
    const [rate, setRate] = useState(10)
    const [term, setTerm] = useState(90)
    const [startDate, setStartDate] = useState(new Date())
    const [maturityDate, setMaturityDate] = useState()
    const [rateType, setRateType] = useState('Fixed')
    const [minFill, setMinFill] = useState(90)

    return (
        <>
            <Navbar userType={props.userType} setIsLoggedIn={props.setIsLoggedIn} refreshAll={props.refreshAll} setRefreshAll={props.setRefreshAll} />
            <Container fixed>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 8,
                    }}
                >
                    <Container maxWidth="lg">
                        <Typography sx={{ mb: 3 }} variant="h6">
                            Issue Loan Request
                        </Typography>
                        <Body
                            currentUser={props.currentUser}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            rate={rate}
                            setRate={setRate}
                            term={term}
                            setTerm={setTerm}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            maturityDate={maturityDate}
                            setMaturityDate={setMaturityDate}
                            rateType={rateType}
                            setRateType={setRateType}
                            minFill={minFill}
                            setMinFill={setMinFill}
                        />
                    </Container>
                </Box>
            </Container>
        </>
    )
}

export default LoanRequest
