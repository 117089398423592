import React, { useState } from 'react'
import '../styles/App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './Login/Login'
import Dashboard from './Dashboard/Dashboard'
import LoanDetails from './LoanDetails/LoanDetails'
import Accounts from './Accounts/Accounts'
import LoanRequest from './LoanRequest/LoanRequest'
import Lending from './Lending/Lending'

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    // 0 = regular, 1 = admin
    const [currentUser, setCurrentUser] = useState({})
    const [userType, setUserType] = useState(null)
    const [allUsers, setAllUsers] = useState([])

    const [selectedLoanDetailsItem, setSelectedLoanDetailsItem] = useState('')
    const [activeLoanInvestments, setActiveLoanInvestments] = useState([])

    const [refreshAll, setRefreshAll] = useState(false)

    // window.location.reload()

    return (
        <div className="App">
            <BrowserRouter>
                {isLoggedIn === false ? (
                    <Login setIsLoggedIn={setIsLoggedIn} setCurrentUser={setCurrentUser} setAllUsers={setAllUsers} setUserType={setUserType} />
                ) : (
                    <Routes>
                        <Route
                            exact
                            path="/"
                            caseSensitive={false}
                            element={
                                <Dashboard
                                    userType={currentUser.userType}
                                    setIsLoggedIn={setIsLoggedIn}
                                    currentUser={currentUser}
                                    setSelectedLoanDetailsItem={setSelectedLoanDetailsItem}
                                    allUsers={allUsers}
                                    setActiveLoanInvestments={setActiveLoanInvestments}
                                    activeLoanInvestments={activeLoanInvestments}
                                    setCurrentUser={setCurrentUser}
                                    refreshAll={refreshAll}
                                    setRefreshAll={setRefreshAll}
                                />
                            }
                        />
                        <Route
                            exact
                            path="/loan-details"
                            caseSensitive={false}
                            element={
                                <LoanDetails
                                    userType={currentUser.userType}
                                    setIsLoggedIn={setIsLoggedIn}
                                    currentUser={currentUser}
                                    selectedLoanDetailsItem={selectedLoanDetailsItem}
                                    allUsers={allUsers}
                                    refreshAll={refreshAll}
                                    setRefreshAll={setRefreshAll}
                                />
                            }
                        />
                        {currentUser.userType === 0 && (
                            <>
                                <Route
                                    path="/loan-request"
                                    caseSensitive={false}
                                    element={
                                        <LoanRequest
                                            userType={currentUser.userType}
                                            currentUser={currentUser}
                                            allUsers={allUsers}
                                            setIsLoggedIn={setIsLoggedIn}
                                            refreshAll={refreshAll}
                                            setRefreshAll={setRefreshAll}
                                        />
                                    }
                                />
                                <Route
                                    path="/lending"
                                    caseSensitive={false}
                                    element={
                                        <Lending
                                            userType={currentUser.userType}
                                            currentUser={currentUser}
                                            setCurrentUser={setCurrentUser}
                                            allUsers={allUsers}
                                            setIsLoggedIn={setIsLoggedIn}
                                            refreshAll={refreshAll}
                                            setRefreshAll={setRefreshAll}
                                        />
                                    }
                                />
                            </>
                        )}
                        {currentUser.userType === 1 && (
                            <Route
                                path="/accounts"
                                caseSensitive={false}
                                element={<Accounts userType={currentUser.userType} setIsLoggedIn={setIsLoggedIn} refreshAll={refreshAll} setRefreshAll={setRefreshAll} />}
                            />
                        )}
                    </Routes>
                )}
            </BrowserRouter>
        </div>
    )
}

export default App
