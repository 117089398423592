import React from 'react'
import { Box, Button, Container } from '@mui/material';

function DashboardNavigation() {
    return (
        <Box sx={{ 
            mt: 3,
            textAlign: 'right',
            mr: 3
        }}>
            <Button
                color="primary"
                variant="contained"
                sx={{mr: 2}}
            >
                Run Auction
            </Button>
            <Button
                color="primary"
                variant="contained"
                sx={{mr: 2}}
            >
                Distribute Loan
            </Button>
            <Button
                color="primary"
                variant="contained"
            >
                Top Up Borrower
            </Button>
        </Box>
    )
}

export default DashboardNavigation