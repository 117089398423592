import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { ethers } from 'ethers'
import TOKEN from '../../ABI/TOKEN.json'
import { useWeb3Data } from '../../context/Web3Context'
import { RefreshOutlined } from '@mui/icons-material'

function Body(props) {
    const { web3Onboard, notify, connectedWallets, wallet, connect, provider, disconnect, setChain, connectedChain } = useWeb3Data()

    const [balance, setBalance] = useState(props.currentUser.userData.usdbBalance)

    const reloadBalance = async (index) => {
        let usdbContract = new ethers.Contract(process.env.REACT_APP_TOKEN_ADDRESS, TOKEN, provider)
        const usdbBalance = await usdbContract.balanceOf(wallet?.accounts[0]?.address)
        let newBalance = ethers.utils.formatUnits(usdbBalance, 18)
        newBalance = parseInt(newBalance).toFixed(2).toString()
        setBalance(newBalance)

        // props.setCurrentUser(userData.usdbBalance
        // console.log(props.currentUser)
        let newCurUser = { ...props.currentUser }
        newCurUser.userData.usdbBalance = balance
        props.setCurrentUser(newCurUser)
        // console.log(props.currentUser.userData.usdbBalance)
    }

    useEffect(() => {
        reloadBalance()
    }, [props.refreshAll])

    return (
        <Grid container rowSpacing={1} p={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} p={2}>
                <TextField fullWidth aria-readonly={true} id="account" label="Account" name="account" required variant="outlined" value={props.currentUser.userName} />
            </Grid>
            <Grid item xs={6} p={2}>
                <TextField
                    fullWidth
                    aria-readonly={true}
                    label="USDB Balance "
                    name="usdbBalance"
                    id="usdbBalance"
                    variant="outlined"
                    value={ethers.utils.commify(balance)}
                    //InputProps={{ endAdornment: <RefreshOutlined onClick={() => reloadBalance()} /> }}
                />
            </Grid>
        </Grid>
    )
}

export default Body
