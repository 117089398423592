import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip, Grid } from '@mui/material'
import { ChartBar as ChartBarIcon } from '../../assets/icons/chart-bar'
import NavItem from './NavItem'
import { Bell as BellIcon } from '../../assets/icons/bell'
import { Users as UsersIcon } from '../../assets/icons/users'
import { ShoppingBag as BagIcon } from '../../assets/icons/shopping-bag'
import Logo from '../../assets/logo.png'
import Logout from '@mui/icons-material/Logout'
import { useWeb3Data } from '../../context/Web3Context'
import RefreshIcon from '@mui/icons-material/Refresh';

function Navbar(props) {
    const { notify, wallet, disconnect } = useWeb3Data()

    const handleRefresh = () => {
        props.setRefreshAll(!props.refreshAll)
        notify.notification({
            eventCode: 'none',
            type: 'hint',
            message: 'Fetching data...',
            autoDismiss: 3000,
        })
    }

    const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        position: 'inherit',
        width: '100% !important',
        borderRadius: '0px !important',
    }))

    const navItems = [
        {
            href: '/',
            icon: <ChartBarIcon fontSize="small" />,
            title: 'Dashboard',
            userType: 1,
        },
        {
            href: '/accounts',
            icon: <UsersIcon fontSize="small" />,
            title: 'Accounts',
            userType: 1,
        },
        {
            href: '/',
            icon: <ChartBarIcon fontSize="small" />,
            title: 'Dashboard',
            userType: 0,
        },
        {
            href: '/loan-request',
            icon: <BagIcon fontSize="small" />,
            title: 'Loan Request',
            userType: 0,
        },
    ]

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: { lg: 105 },
                    width: { lg: 'calc(100% - 200px)' },
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        left: 0,
                        px: 2,
                        backgroundColor: 'neutral.900',
                        borderRadius: 0,
                    }}
                >
                    <Box component="img" sx={{ height: 30, ml: 5 }} alt="Your logo." src={Logo} />

                    <Box sx={{ ml: 10, display: 'flex' }}>
                        {navItems.map(
                            (item) =>
                                props.userType === item.userType && (
                                    <Grid item lg={2} key={item.title}>
                                        <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
                                    </Grid>
                                )
                        )}
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title="Refresh" onClick={() => handleRefresh()}>
                        <IconButton sx={{ ml: 1 }} color='primary'>
                            <RefreshIcon fontSize="small" sx={{width: 27, height: 27}} />
                        </IconButton>
                    </Tooltip>
                    <Avatar
                        onClick={() => {
                            disconnect(wallet).then(
                                notify.notification({
                                    eventCode: 'none',
                                    type: 'success',
                                    message: 'Logged Out',
                                    autoDismiss: 3000,
                                })
                            )
                            props.setIsLoggedIn(false)
                        }}
                        sx={{
                            height: 40,
                            width: 40,
                            ml: 1,
                            color: '#2B84C3',
                            backgroundColor: 'black',
                            cursor: 'pointer',
                        }}
                    >
                        <Logout fontSize="medium" />
                    </Avatar>
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    )
}

export default Navbar
