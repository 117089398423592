import React, {useEffect, useState} from 'react';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import {
	Box,
	Table,
	TableContainer,
	Typography,
	Paper,
	Toolbar,
	Container,
	Button
} from '@mui/material';
import Navbar from '../Navbar/Navbar';
import {ethers} from 'ethers';
import TOKEN from '../../ABI/TOKEN.json';
import {useWeb3Data} from '../../context/Web3Context';
import RefreshIcon from '@mui/icons-material/Refresh';

let fakeData = [
	{
		accountName: 'Coinbase',
		walletAddress: '0x6ea168b6c6e2e4659de9dd94187c1d205c1f2e14',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Goldman Sachs',
		walletAddress: '0x667ba780073d13ec0cbb752a447c8823d50589ca',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'OneRiver',
		walletAddress: '0x9ee32a535e780ed404f2860e1d41f7647369903a',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Admin',
		walletAddress: '0x4c7cd1ea29e7621f875eb9289e6f42a1ab17e2aa',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Google',
		walletAddress: '0xe581f08c75ecaaf5918b952c3138bbef2c208ba9',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Apple',
		walletAddress: '0xa634cf696c39c79663c890703e24d4ae58ecd9d9',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Brevan Howard',
		walletAddress: '0xe6f13cb39321e4534441b0f5281643e9bfad4c2d',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'JP Morgan',
		walletAddress: '0x4a3fc59fff02ffc8d11abdbd3b13692f6bf29ad0',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Bank A (AA)',
		walletAddress: '0x74eb05bf06834e02a71a5928a23a35f38034fa0e',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Bank B (AAA)',
		walletAddress: '0x064b8c6b46a5d3d1779bb4a84a88f0d561fbec80',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Bank C (AA)',
		walletAddress: '0xb0320d9d0e9ee0bcf212aa34610029f809ae7d43',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Corporate A (AAA)',
		walletAddress: '0xd509c22482895c538d302556756630520677b76f',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Corporate B (AA)',
		walletAddress: '0xc77eba05ed527793d1c72d8e01ea69f39c41774c',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Corporate C (AA)',
		walletAddress: '0x60d53bb7244681cf93d5972f1e17ca09ee3eff80',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Corporate D (A)',
		walletAddress: '0x6c2a7d509339552b83df90fa9959a1e583973c6a',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'Corporate E (A)',
		walletAddress: '0x9977245c7853c414bda6287ec547275df2b118d7',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'Low Risk',
		investorStatus: 'QIB'
	},
	{
		accountName: 'MK Questionable Bank',
		walletAddress: '0xe9ad72ca0316f08a5dcf8daf206b9498eece3668',
		usdbBalance: 'Loading...',
		amlorkycStatus: 'High Risk',
		investorStatus: 'QIB'
	}
];

function Accounts(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const getBalances = async () => {
		let usdbContract = new ethers.Contract(
			process.env.REACT_APP_TOKEN_ADDRESS,
			TOKEN,
			provider
		);

		for (let i = 0; i < fakeData.length; i++) {
			const usdbBalance = await usdbContract.balanceOf(
				fakeData[i].walletAddress
			);
			fakeData[i].usdbBalance = ethers.utils.formatUnits(usdbBalance, 18);
			fakeData[i].usdbBalance = parseInt(fakeData[i].usdbBalance)
				.toFixed(2)
				.toString();
		}

		setData(fakeData);
		setIsLoading(false);
	};

	useEffect(() => {
		getBalances();
	}, [props.refreshAll]);
	// }, [refresh])
	/*
    const refreshData = () => {
        setRefresh(!refresh)
        notify.notif
            eventCode: 'none',
            type: 'hint',
            message: 'Fetching data...',
            autoDismiss: 3000,
        })
    }
    */
	return (
		<>
			<Navbar
				userType={props.userType}
				setIsLoggedIn={props.setIsLoggedIn}
				refreshAll={props.refreshAll}
				setRefreshAll={props.setRefreshAll}
			/>
			<Container fixed>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						py: 8
					}}
				>
					<Container maxWidth={false}>
						<Box sx={{minWidth: 1050, mt: 3}}>
							<Paper sx={{width: '100%', mb: 2}}>
								<Toolbar
									sx={{
										pl: {sm: 2},
										pr: {xs: 1, sm: 1}
									}}
								>
									<Typography
										sx={{flex: '1 1 100%'}}
										variant='h6'
										id='tableTitle'
										component='div'
									>
										All System Accounts
									</Typography>
									{/* <RefreshIcon onClick={() => refreshData()} styles={{ cursor: 'pointer' }} /> */}
								</Toolbar>
								<TableContainer>
									<Table>
										<Header setData={setData} />
										<Body data={data} isLoading={isLoading} />
									</Table>
								</TableContainer>
								<Pagination data={data} />
							</Paper>
						</Box>
					</Container>
				</Box>
			</Container>
		</>
	);
}

export default Accounts;
