import React from 'react'
import {
    CardContent,
    Grid,
    TextField
} from '@mui/material';
import { format } from 'date-fns';

function Body(props)  {
    return (
        <CardContent>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                <TextField
                    fullWidth
                    aria-readonly={true}
                    label="Instrument Name"
                    name="instrumentName"
                    //onChange={handleChange}
                    required
                    value={props.selectedLoanDetailsItem.instrumentName}
                    variant="outlined"
                />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="size"
                        name="loanSize"
                        //onChange={handleChange}
                        required
                        value={props.selectedLoanDetailsItem.loanSize}
                        variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="CUSIP"
                        name="cusip"
                        //onChange={handleChange}
                        required
                        value={props.selectedLoanDetailsItem.cusip}
                        variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="Start Date"
                        name="startDate"
                        //onChange={handleChange}
                        required
                        value={format(props.selectedLoanDetailsItem.startDate, 'dd/MM/yyyy')}
                        variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="Issuer Name"
                        name="issuerName"
                        //onChange={handleChange}
                        required
                        value={props.selectedLoanDetailsItem.issuerName}
                        variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="Maturity Date"
                        name="maturityDate"
                        //onChange={handleChange}
                        required
                        value={format(props.selectedLoanDetailsItem.maturityDate, 'dd/MM/yyyy')}
                        variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="Price"
                        name="price"
                        //onChange={handleChange}
                        required
                        value={props.selectedLoanDetailsItem.price}
                        variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        aria-readonly={true}
                        label="Rate Type"
                        name="rateType"
                        //onChange={handleChange}
                        required
                        //value={props.selectedLoanDetailsItem.rateType}
                        value="Fixed"
                        variant="outlined"
                    />
                </Grid>
                
            </Grid>
        </CardContent>
    )
}

export default Body