import React, {useState} from 'react'
import Header from './Header'
import Body from './Body'
import Pagination from './Pagination'
import {
    Box,
    Table,
    TableContainer,
    Typography,
    Paper,
    Toolbar,
    Container
} from '@mui/material';

const fakeData = [
  {
    "instrumentName": "AAPL_FIXED97.43_90D_03062022",
    "cusip": 706229687,
    "issuerName": "Apple",
    "loanSize": "$650,000,000.00 ",
    "price": 97.43,
    "startDate": 44626,
    "maturityDate": 44716
  },
  {
    "instrumentName": "GOOG_FIXED96.23_180D_04152022",
    "cusip": 729665366,
    "issuerName": "Google",
    "loanSize": "$425,000,000.00 ",
    "price": 96.23,
    "startDate": 44666,
    "maturityDate": 44846
  },
  {
    "instrumentName": "GOOG_FIXED98.65_90D_03122022",
    "cusip": 680216026,
    "issuerName": "Google",
    "loanSize": "$300,000,000.00 ",
    "price": 98.65,
    "startDate": 44632,
    "maturityDate": 44722
  },
  {
    "instrumentName": "AAPL_FIXED96.87_270D_02262022",
    "cusip": 908746233,
    "issuerName": "Apple",
    "loanSize": "$560,000,000.00 ",
    "price": 96.87,
    "startDate": 44618,
    "maturityDate": 44888
  },
  {
    "instrumentName": "AAPL_FIXED99.11_90D_04102022",
    "cusip": 768968378,
    "issuerName": "Apple",
    "loanSize": "$1,100,000,000.00 ",
    "price": 99.11,
    "startDate": 44661,
    "maturityDate": 44751
  },
  {
    "instrumentName": "GS_FIXED95.75_180D_04172022",
    "cusip": 330202865,
    "issuerName": "Goldman Sachs",
    "loanSize": "$440,000,000.00 ",
    "price": 95.75,
    "startDate": 44668,
    "maturityDate": 44848
  },
  {
    "instrumentName": "COIN_FIXED93.65_90D_03122022",
    "cusip": 290935233,
    "issuerName": "Coinbase",
    "loanSize": "$275,000,000.00 ",
    "price": 93.65,
    "startDate": 44632,
    "maturityDate": 44722
  },
  {
    "instrumentName": "COIN_FIXED94.12_180D_03152022",
    "cusip": 554407677,
    "issuerName": "Coinbase",
    "loanSize": "$260,000,000.00 ",
    "price": 94.12,
    "startDate": 44635,
    "maturityDate": 44815
  },
  {
    "instrumentName": "COIN_FIXED95.55_270D_04082022",
    "cusip": 389613766,
    "issuerName": "Coinbase",
    "loanSize": "$612,000,000.00 ",
    "price": 95.55,
    "startDate": 44659,
    "maturityDate": 44929
  }
];

function LenderSummary(props) {

    const [data, setData] = useState(fakeData)

    return (
        <Container maxWidth={false}>
            <Box sx={{ mt: 3 }}>
                <Box sx={{ minWidth: 1050 }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                            }}
                        >
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Lender Summary
                            </Typography>
                        </Toolbar>
                        <TableContainer>
                            <Table>
                                <Header 
                                    setData={setData}
                                />
                                <Body 
                                    data={data}
                                    setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
                                />
                            </Table>
                        </TableContainer>
                        <Pagination 
                            data={data}
                        />
                    </Paper>
                </Box>
            </Box>
        </Container>
    )
}

export default LenderSummary