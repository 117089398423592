import React from 'react'
import Item from './Item'
import { TableBody, TableCell, TableRow } from '@mui/material';

function Body(props) {

    return (
        <TableBody>
            {
                props.isLoading ?
                <TableRow hover>
                    <TableCell align="left">
                        Loading...
                    </TableCell>
                </TableRow>
                :
                props.data.map((value, index) => (
                    <Item key={index} value={value} />
                ))
            }
        </TableBody>
    )
}

export default Body