import React, {useEffect, useState} from 'react';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import {
	Box,
	Table,
	TableContainer,
	Typography,
	Paper,
	Toolbar,
	Container
} from '@mui/material';
import LOAN from '../../../ABI/LOAN.json';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import {useWeb3Data} from '../../../context/Web3Context';
import {ethers} from 'ethers';

const fakeData = [
	{
		instrumentName: 'COIN_FIXEDTBD_180D_05102022',
		cusip: 472842973,
		lenderName: 'Goldman Sachs',
		loanSize: '$156,250,000.00 ',
		price: 95.65,
		startDate: 44691,
		maturityDate: 44871
	},
	{
		instrumentName: 'COIN_FIXEDTBD_180D_05102022',
		cusip: 472842973,
		lenderName: 'Breavan Howard',
		loanSize: '$208,330,000.00 ',
		price: 94.45,
		startDate: 44691,
		maturityDate: 44871
	},
	{
		instrumentName: 'COIN_FIXEDTBD_180D_05102022',
		cusip: 472842973,
		lenderName: 'JP Morgan',
		loanSize: '$312,500,000.00 ',
		price: 95.05,
		startDate: 44691,
		maturityDate: 44871
	},
	{
		instrumentName: 'AAPL_FIXEDTBD_90D_06012022',
		cusip: 671654673,
		lenderName: 'Goldman Sachs',
		loanSize: '$400,000,000.00 ',
		price: 99.05,
		startDate: 44713,
		maturityDate: 44803
	},
	{
		instrumentName: 'AAPL_FIXEDTBD_90D_06012022',
		cusip: 671654673,
		lenderName: 'Breavan Howard',
		loanSize: '$300,000,000.00 ',
		price: 98.35,
		startDate: 44713,
		maturityDate: 44803
	},
	{
		instrumentName: 'AAPL_FIXEDTBD_90D_06012022',
		cusip: 671654673,
		lenderName: 'JP Morgan',
		loanSize: '$300,000,000.00',
		price: 99.25,
		startDate: 44713,
		maturityDate: 44803
	},
	{
		instrumentName: 'GOOG_FIXEDTBD_270D_05052022',
		cusip: 267247498,
		lenderName: 'Goldman Sachs',
		loanSize: '$372,500,000.00',
		price: 95.55,
		startDate: 44686,
		maturityDate: 44956
	},
	{
		instrumentName: 'GOOG_FIXEDTBD_270D_05052022',
		cusip: 267247498,
		lenderName: 'Breavan Howard',
		loanSize: '$248,330,000.00',
		price: 97.45,
		startDate: 44686,
		maturityDate: 44956
	},
	{
		instrumentName: 'GOOG_FIXEDTBD_270D_05052022',
		cusip: 267247498,
		lenderName: 'JP Morgan',
		loanSize: '$372,500,000.00',
		price: 95.45,
		startDate: 44686,
		maturityDate: 44956
	}
];

function LoanBids(props) {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	//const [refresh, setRefresh] = useState(false)
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	const getBidOffers = async () => {
		let contract = new ethers.Contract(
			process.env.REACT_APP_LOAN_ADDRESS,
			LOAN,
			provider
		);
		let newBidOffers = [];

		try {
			let totalNumOfLoans = await contract
				.counter()
				.then((res) => parseInt(res))
				.catch((err) => console.log(err));

			for (let i = 1; i <= totalNumOfLoans; i++) {
				let loanAddress = await contract
					.loans(i)
					.then((loanAddress) => {
						return loanAddress;
					})
					.catch((err) => {
						console.log('err', err);
					});

				let loanContract = new ethers.Contract(
					loanAddress,
					LOANFAUCET,
					provider
				);

				let loanData = await loanContract
					.getLibVals()
					.then((res) => {
						console.log(ethers.utils.formatUnits(res[0], '18'));
						return ethers.utils.formatUnits(res[0], '18');
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				console.log('loanData', loanData);

				let borrowerAddress = await loanContract
					.borrowerAddress()
					.then((addr) => {
						return addr;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let loanState = await loanContract
					.loanState()
					.then((state) => {
						// console.log(`loanState 58569065${i}`, parseInt(state))
						return parseInt(state); //error here
					})
					.catch((err) => {
						console.log(err);
					});

				let startDate = await loanContract
					.startDate()
					.then((num) => {
						console.log('startDate', num);

						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let loanRate = await loanContract
					.getLibVals()
					.then((state) => {
						// console.log('loanRate', state)

						console.log('loanRate', (10000 - state[2]) / 100);
						console.log('loanRate', parseInt(state[2]));

						return (10000 - parseInt(state[2])) / 100;
					})
					.catch((err) => {
						console.log(err);
					});

				let maturityDate = await loanContract
					.maturityDate()
					.then((num) => {
						console.log('maturityDate', num);
						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				if (
					borrowerAddress.toLowerCase() !== wallet?.accounts[0]?.address &&
					props.userType === 0
				) {
					let myBids = await loanContract
						.getBid(wallet?.accounts[0]?.address)
						.then((bid) => {
							console.log(`myBids ${i}`, ethers.utils.formatUnits(bid[0], 18));
							let name = {...bid};
							name.bidSize = ethers.utils.formatUnits(bid[0], 18);
							name.bidRate = ethers.utils.formatUnits(bid[1], 18);

							console.log('name', name);
							console.log('myBids.activeBid :', bid[6]);
							return name;
						})
						.catch((err) => {
							console.log(`err for ${i}`, err);
						});

					console.log(myBids);
					if (
						parseInt(myBids.bidPrice) !== 0 &&
						myBids[6] === true &&
						loanState === 1
					) {
						console.log('got into here');
						newBidOffers.push({
							instrumentName: `${
								props.allUsers[borrowerAddress.toLowerCase()].userData.shortName
							}_FIXED_TBD_58569065${i}`,
							cusip: `58569065${i}`,
							lenderName:
								props.allUsers[borrowerAddress.toLowerCase()].userName,
							loanSize: '$' + ethers.utils.commify(myBids.bidSize),
							price: ((1000000 - myBids[2]) / 10000).toFixed(3),
							startDate: startDate,
							maturityDate: maturityDate,
							contract: loanAddress
						});
						console.log('myBids[2]', myBids[2]);
					}
				} else if (props.userType === 1) {
					for (let user in props.allUsers) {
						let myBids = await loanContract
							.getBid(user)
							.then((bid) => {
								let name = {...bid};
								name.bidSize = ethers.utils.formatUnits(bid[0], 18);
								name.bidRate = ethers.utils.formatUnits(bid[1], 18);

								// console.log('name', name);

								return name;
							})
							.catch((err) => {
								console.log(`err for ${i}`, err);
							});
						if (
							parseInt(myBids.bidPrice) !== 0 &&
							myBids[6] === true &&
							loanState === 1
						) {
							newBidOffers.push({
								instrumentName: `${
									props.allUsers[borrowerAddress.toLowerCase()].userData
										.shortName
								}_FIXED_TBD_58569065${i}`,
								cusip: `58569065${i}`,
								lenderName: props.allUsers[user].userName,
								loanSize: '$' + ethers.utils.commify(myBids.bidSize),
								price: ((1000000 - myBids[2]) / 10000).toFixed(3),
								startDate: startDate,
								maturityDate: maturityDate,
								contract: loanAddress
							});
							console.log('myBids[2]', parseInt(myBids[2]));
						}
					}
				} else {
					console.log(`No Bids Found For 58569065${i}`);
				}
			}
		} catch (err) {
			console.log(err);
		}
		setData(newBidOffers);
		setIsLoading(false);
	};

	useEffect(() => {
		getBidOffers();
	}, [wallet, provider, props.allUsers, props.userType, props.refreshAll]);

	return (
		<Container maxWidth={false}>
			<Box sx={{mt: 3}}>
				<Box sx={{minWidth: 1050}}>
					<Paper sx={{width: '100%', mb: 2}}>
						<Toolbar
							sx={{
								pl: {sm: 2},
								pr: {xs: 1, sm: 1}
							}}
						>
							<Typography
								sx={{flex: '1 1 100%'}}
								variant='h6'
								id='tableTitle'
								component='div'
							>
								Loan Bids
							</Typography>
							{/* <button onClick={() => setRefresh(!refresh)}>Refresh</button> */}
						</Toolbar>
						<TableContainer>
							<Table>
								<Header setData={setData} userType={props.userType} />
								<Body
									data={data}
									userType={props.userType}
									isLoading={isLoading}
								/>
							</Table>
						</TableContainer>

						<Pagination data={data} />
					</Paper>
				</Box>
			</Box>
		</Container>
	);
}

export default LoanBids;
