import React, {useState} from 'react'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Container
} from '@mui/material';
import Navbar from '../Navbar/Navbar'
import Body from './Body'
import LenderSummary from './LenderSummary/LenderSummary'

function LoanDetails(props) {
    return (
        <>
            <Navbar 
                userType={props.userType}
                setUser={props.setUser}
                setIsLoggedIn={props.setIsLoggedIn}
                refreshAll={props.refreshAll} 
                setRefreshAll={props.setRefreshAll}
            />
            <Container fixed>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 8
                    }}
                >
                    <Container maxWidth="lg">
                        <Card>
                            <CardHeader
                                title="Loan Details"
                            />
                            <Divider />
                            <Body selectedLoanDetailsItem={props.selectedLoanDetailsItem} />
                            <Divider />
                            <LenderSummary />
                        </Card>
                    </Container>
                </Box>
            </Container>
        </>
    )
}

export default LoanDetails