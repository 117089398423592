import React, {useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import {blue} from '@mui/material/colors';
import {Box, Button, Container, Typography} from '@mui/material';
import {useWeb3Data} from '../../context/Web3Context';
import {ethers} from 'ethers';
import TOKEN from '../../ABI/TOKEN.json';

const users = {
	'0x667ba780073d13ec0cbb752a447c8823d50589ca': {
		userName: 'Goldman Sachs',
		walletID: '00923232323',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Goldman Sachs',
			walletAddress: '0x667ba780073d13ec0cbb752a447c8823d50589ca',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'GS'
		}
	},
	'0x6ea168b6c6e2e4659de9dd94187c1d205c1f2e14': {
		userName: 'Coinbase',
		walletID: '00923232324',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Coinbase',
			walletAddress: '0x6ea168b6c6e2e4659de9dd94187c1d205c1f2e14',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'COIN'
		}
	},
	'0x4c7cd1ea29e7621f875eb9289e6f42a1ab17e2aa': {
		userName: 'Admin',
		walletID: '00923232325',
		userType: 1,
		//userType: 'User',
		userData: {
			accountName: 'Admin',
			walletAddress: '0x4c7cd1ea29e7621f875eb9289e6f42a1ab17e2aa',
			usdbBalance: 0,
			amlorkycStatus: 'N/A',
			investorStatus: 'N/A',
			shortName: 'ADMN'
		}
	},
	'0x9ee32a535e780ed404f2860e1d41f7647369903a': {
		userName: 'OneRiver',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'OneRiver',
			walletAddress: '0x9ee32a535e780ed404f2860e1d41f7647369903a',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'OR'
		}
	},
	'0xe581f08c75ecaaf5918b952c3138bbef2c208ba9': {
		userName: 'Google',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Google',
			walletAddress: '0xe581f08c75ecaaf5918b952c3138bbef2c208ba9',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'GOOG'
		}
	},
	'0xa634cf696c39c79663c890703e24d4ae58ecd9d9': {
		userName: 'Apple',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Apple',
			walletAddress: '0xa634cf696c39c79663c890703e24d4ae58ecd9d9',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'AAPL'
		}
	},
	'0xe6f13cb39321e4534441b0f5281643e9bfad4c2d': {
		userName: 'Brevan Howard',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Brevan Howard',
			walletAddress: '0xe6f13cb39321e4534441b0f5281643e9bfad4c2d',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'BH'
		}
	},
	'0x4a3fc59fff02ffc8d11abdbd3b13692f6bf29ad0': {
		userName: 'JP Morgan',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'JP Morgan',
			walletAddress: '0x4a3fc59fff02ffc8d11abdbd3b13692f6bf29ad0',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0x74eb05bf06834e02a71a5928a23a35f38034fa0e': {
		userName: 'Bank A (AA)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Bank A (AA)',
			walletAddress: '0x74eb05bf06834e02a71a5928a23a35f38034fa0e',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0x064b8c6b46a5d3d1779bb4a84a88f0d561fbec80': {
		userName: 'Bank B (AAA)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Bank B (AAA)',
			walletAddress: '0x064b8c6b46a5d3d1779bb4a84a88f0d561fbec80',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0xb0320d9d0e9ee0bcf212aa34610029f809ae7d43': {
		userName: 'Bank C (AA)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Bank C (AA)',
			walletAddress: '0xb0320d9d0e9ee0bcf212aa34610029f809ae7d43',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0xd509c22482895c538d302556756630520677b76f': {
		userName: 'Corporate A (AAA)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Corporate A (AAA)',
			walletAddress: '0xd509c22482895c538d302556756630520677b76f',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0xc77eba05ed527793d1c72d8e01ea69f39c41774c': {
		userName: 'Corporate B (AA)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Corporate B (AA)',
			walletAddress: '0xc77eba05ed527793d1c72d8e01ea69f39c41774c',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0x60d53bb7244681cf93d5972f1e17ca09ee3eff80': {
		userName: 'Corporate C (AA)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Corporate C (AA)',
			walletAddress: '0x60d53bb7244681cf93d5972f1e17ca09ee3eff80',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0x6c2a7d509339552b83df90fa9959a1e583973c6a': {
		userName: 'Corporate D (A)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Corporate D (A)',
			walletAddress: '0x6c2a7d509339552b83df90fa9959a1e583973c6a',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0x9977245c7853c414bda6287ec547275df2b118d7': {
		userName: 'Corporate E (A)',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'Corporate E (A)',
			walletAddress: '0x9977245c7853c414bda6287ec547275df2b118d7',
			usdbBalance: 0,
			amlorkycStatus: 'Low Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	},
	'0xe9ad72ca0316f08a5dcf8daf206b9498eece3668': {
		userName: 'MK Questionable Bank',
		walletID: '00923232326',
		userType: 0,
		//userType: 'User',
		userData: {
			accountName: 'MK Questionable Bank',
			walletAddress: '0xe9ad72ca0316f08a5dcf8daf206b9498eece3668',
			usdbBalance: 0,
			amlorkycStatus: 'High Risk',
			investorStatus: 'QIB',
			shortName: 'JPM'
		}
	}
};

function ConnectWallet(props) {
	const {notify, connectedWallets, wallet, connect, provider} = useWeb3Data();

	const selectUser = async (address) => {
		let usdbContract = new ethers.Contract(
			process.env.REACT_APP_TOKEN_ADDRESS,
			TOKEN,
			provider
		);

		const usdbBalance = await usdbContract.balanceOf(address);
		users[address].userData.usdbBalance = ethers.utils.formatUnits(
			usdbBalance,
			18
		);

		props.setIsLoggedIn(true);
		props.setCurrentUser(users[address]);
		// console.log('usertype', users[address].userType)
		props.setUserType(users[address].userType);
	};

	useEffect(() => {
		if (wallet && connectedWallets.length > 0) {
			// props.setIsLoggedIn(true)
			// console.log(wallet?.accounts[0]?.address)

			selectUser(wallet?.accounts[0]?.address);
		}
		props.setAllUsers(users);
	}, [connectedWallets, wallet]);

	return (
		<div>
			{!wallet && (
				<Button
					color='primary'
					fullWidth
					size='large'
					type='submit'
					variant='contained'
					// onClick={() => setIsOpen(true)}
					onClick={() => {
						connect().then((e) => {
							console.log(e);
							if (e === undefined) {
								notify.notification({
									eventCode: 'none',
									type: 'success',
									message: 'Logged In',
									autoDismiss: 3000
								});
							}
						});
					}}
				>
					Connect Wallet
				</Button>
			)}
		</div>
	);
}

export default ConnectWallet;
