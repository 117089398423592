import React, {useEffect, useState} from 'react';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import {
	Box,
	Table,
	TableContainer,
	Typography,
	Paper,
	Toolbar,
	Container
} from '@mui/material';
import {ethers} from 'ethers';
import {useWeb3Data} from '../../../context/Web3Context';
import LOAN from '../../../ABI/LOAN.json';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';

const fakeData = [
	{
		instrumentName: 'AAPL_FIXED97.43_90D_03062022',
		cusip: 706229687,
		issuerName: 'Apple',
		loanSize: '$650,000,000.00 ',
		price: 97.43,
		startDate: 44626,
		maturityDate: 44716
	},
	{
		instrumentName: 'GOOG_FIXED96.23_180D_04152022',
		cusip: 729665366,
		issuerName: 'Google',
		loanSize: '$425,000,000.00 ',
		price: 96.23,
		startDate: 44666,
		maturityDate: 44846
	},
	{
		instrumentName: 'GOOG_FIXED98.65_90D_03122022',
		cusip: 680216026,
		issuerName: 'Google',
		loanSize: '$300,000,000.00 ',
		price: 98.65,
		startDate: 44632,
		maturityDate: 44722
	},
	{
		instrumentName: 'AAPL_FIXED96.87_270D_02262022',
		cusip: 908746233,
		issuerName: 'Apple',
		loanSize: '$560,000,000.00 ',
		price: 96.87,
		startDate: 44618,
		maturityDate: 44888
	},
	{
		instrumentName: 'AAPL_FIXED99.11_90D_04102022',
		cusip: 768968378,
		issuerName: 'Apple',
		loanSize: '$1,100,000,000.00 ',
		price: 99.11,
		startDate: 44661,
		maturityDate: 44751
	},
	{
		instrumentName: 'GS_FIXED95.75_180D_04172022',
		cusip: 330202865,
		issuerName: 'Goldman Sachs',
		loanSize: '$440,000,000.00 ',
		price: 95.75,
		startDate: 44668,
		maturityDate: 44848
	},
	{
		instrumentName: 'COIN_FIXED93.65_90D_03122022',
		cusip: 290935233,
		issuerName: 'Coinbase',
		loanSize: '$275,000,000.00 ',
		price: 93.65,
		startDate: 44632,
		maturityDate: 44722
	},
	{
		instrumentName: 'COIN_FIXED94.12_180D_03152022',
		cusip: 554407677,
		issuerName: 'Coinbase',
		loanSize: '$260,000,000.00 ',
		price: 94.12,
		startDate: 44635,
		maturityDate: 44815
	},
	{
		instrumentName: 'COIN_FIXED95.55_270D_04082022',
		cusip: 389613766,
		issuerName: 'Coinbase',
		loanSize: '$612,000,000.00 ',
		price: 95.55,
		startDate: 44659,
		maturityDate: 44929
	}
];

function DebtInstruments(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();
	const [data, setData] = useState([]);
	const [auctionState, setAuctionState] = useState(0);
	// const [isLoading, setIsLoading] = useState(true);
	// const [isLoading, setIsLoading] = useState(true)
	console.log('LOADING');
	console.log(props.isLoading);

	const getLoanActiveOffers = async () => {
		let contract = new ethers.Contract(
			process.env.REACT_APP_LOAN_ADDRESS,
			LOAN,
			provider
		);
		let newActiveLoanOffers = [];

		function addComa(value) {
			if (value.length < 2) {
				if ((value.charAt(0) >= '0' && value.charAt(0) <= '9') || value == '') {
					props.setQuantity(value);
				}
			} else {
				let numArr = value.split('');
				for (let i = 0; i < numArr.length; i++) {
					if (numArr[i] === ',') {
						numArr.splice(i, 1);
					}
				}
				let num = parseInt(numArr.join(''));
				props.setQuantity(num.toLocaleString());
			}
			console.log(value);
		}

		try {
			let numOfLoans = await contract
				.counter()
				.then((res) => {
					return parseInt(res);
				})
				.catch((err) => {
					console.log(err);
				});

			let activeLoanInvestmentContracts = [];

			for (let i = 1; i <= numOfLoans; i++) {
				let loanAddress = await contract
					.loans(i)
					.then((loanAddress) => {
						return loanAddress;
					})
					.catch((err) => {
						console.log('err', err);
					});
				// console.log(loanAddress)

				let loanContract = new ethers.Contract(
					loanAddress,
					LOANFAUCET,
					provider
				);

				let loanData = await loanContract
					.getLibVals()
					.then((res) => {
						console.log(ethers.utils.formatUnits(res[0], '18'));
						return ethers.utils.formatUnits(res[0], '18');
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let minPriceRate = await loanContract
					.minPrice()
					.then((size) => {
						let price = (ethers.utils.formatUnits(size, '18') / loanData) * 100;
						// console.log(price)
						return price.toFixed(3);
						// return ethers.utils.formatUnits(size, '18')
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let borrowerAddress = await loanContract
					.borrowerAddress()
					.then((addr) => {
						return addr;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let status = await loanContract
					.loanObligation()
					.then((status) => {
						console.log('loanObligation', parseInt(status));
						return parseInt(status);
					})
					.catch((err) => {
						console.log(err);
					});

				let loanState = await loanContract
					.loanState()
					.then((state) => {
						return parseInt(state);
					})
					.catch((err) => {
						console.log(err);
					});

				let loanOb = await loanContract
					.loanObligation()
					.then((state) => {
						state = ethers.utils.formatEther(state);
						return state;
					})
					.catch((err) => {
						console.log(err);
					});

				let loanRate = await loanContract
					.getLibVals()
					.then((state) => {
						// console.log('loanRate', state)
						return (1000000 - state[1]) / 10000;
						// 							price: ((1000000 - myBids[2]) / 10000).toFixed(3),
					})
					.catch((err) => {
						console.log(err);
					});

				let startDate = await loanContract
					.startDate()
					.then((num) => {
						console.log('startDate', num);

						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let maturityDate = await loanContract
					.maturityDate()
					.then((num) => {
						console.log('maturityDate', num);
						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				if (
					borrowerAddress.toLowerCase() === wallet?.accounts[0]?.address ||
					status > 0
				) {
					console.log('xxxxxxxxxxxxxxxxxx: ', borrowerAddress.toLowerCase());
					console.log('xxxxxxxxxxxxxxxxxx: ', wallet?.accounts[0]?.address);
					console.log('xxxxxxxxxxxxxxxxxx: ', wallet?.accounts[0]?.address);
					if (loanState > 1 && loanState < 5) {
						console.log(
							'Found Loan Offer From :',
							props.allUsers[
								borrowerAddress.toLowerCase()
							].userName.toUpperCase(),
							props.userType
						);
						newActiveLoanOffers.push({
							instrumentName: `${
								props.allUsers[borrowerAddress.toLowerCase()].userData.shortName
								//}_FIXED_${ethers.utils.commify(loanRate)}_58569065${i}`,
							}_FIXED_${loanRate.toFixed(3)}_58569065${i}`,
							cusip: `58569065${i}`,
							issuerName:
								props.allUsers[borrowerAddress.toLowerCase()].userName,
							loanSize: '$' + ethers.utils.commify(loanOb),
							//price: ethers.utils.commify(loanRate),
							price: loanRate.toFixed(3),
							startDate: startDate,
							maturityDate: maturityDate,
							loanState: loanState,
							contract: loanAddress,
							loanObligation: loanOb,
							thisItemId: 0
						});

						activeLoanInvestmentContracts.push(loanAddress);
					}
				}
			}

			let newActiveLoanInvestments = [];
			for (let i = 0; i < activeLoanInvestmentContracts.length; i++) {
				// console.log('WEU', activeLoanInvestmentContracts[i]);
				for (let j = 0; j < newActiveLoanOffers.length; j++) {
					if (
						activeLoanInvestmentContracts[i] === newActiveLoanOffers[j].contract
					) {
						newActiveLoanInvestments.push({
							contract: activeLoanInvestmentContracts[i],
							intstrumentName: newActiveLoanOffers[j].instrumentName,
							cusip: newActiveLoanOffers[j].cusip,
							price: newActiveLoanOffers[j].price
						});
					}
				}

				console.log('added');
			}

			props.setActiveLoanInvestments(newActiveLoanInvestments);
		} catch (e) {
			console.log(e);
		}

		setData(newActiveLoanOffers);
		// setIsLoading(false);
		props.setIsLoading(false);
	};

	useEffect(() => {
		getLoanActiveOffers();
	}, [props.userType, props.refreshAll]);

	return (
		<Container maxWidth={false}>
			<Box sx={{mt: 3}}>
				<Box sx={{minWidth: 1050}}>
					<Paper sx={{width: '100%', mb: 2}}>
						<Toolbar
							sx={{
								pl: {sm: 2},
								pr: {xs: 1, sm: 1}
							}}
						>
							<Typography
								sx={{flex: '1 1 100%'}}
								variant='h6'
								id='tableTitle'
								component='div'
							>
								Active Loans
							</Typography>
						</Toolbar>
						<TableContainer>
							<Table>
								<Header setData={setData} />
								<Body
									data={data}
									setData={setData}
									setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
									userType={props.userType}
									isLoading={props.isLoading}
								/>
							</Table>
						</TableContainer>
						<Pagination data={data} />
					</Paper>
				</Box>
			</Box>
		</Container>
	);
}

export default DebtInstruments;
