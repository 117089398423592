import React from 'react'
import Item from './Item'
import {
    TableBody,
} from '@mui/material';

function Body(props) {
    return (
        <TableBody>
            {
                props.data.map((value, index) => (
                    <Item 
                        key={index} 
                        value={value} 
                        index={index} 
                        setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
                        data={props.data}
                    />
                ))
            }
        </TableBody>
    )
}

export default Body