import React from 'react'
import { TableRow, TableCell } from '@mui/material'
import { format } from 'date-fns'

function Item(props) {
    return (
        <TableRow hover>
            <TableCell align="left">{props.value.instrumentName}</TableCell>
            <TableCell align="left">{props.value.cusip}</TableCell>
            <TableCell align="left">{props.value.lenderName}</TableCell>
            <TableCell align="left">{props.value.loanSize}</TableCell>
            <TableCell align="left">{props.value.price}</TableCell>
            <TableCell align="left">{props.value.startDate}</TableCell>
            <TableCell align="left">{props.value.maturityDate}</TableCell>
        </TableRow>
    )
}

export default Item
