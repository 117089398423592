import React, {useEffect, useState} from 'react';
import {
	Card,
	Typography,
	Container,
	TableContainer,
	Table,
	Toolbar,
	Box
} from '@mui/material';
import Navbar from '../Navbar/Navbar';
import Body from './Body';
import Submit from './Submit';
import Header from './Table/Header';
import TableBody from './Table/Body';
import Pagination from './Table/Pagination';
import {useWeb3Data} from '../../context/Web3Context';
import {ethers} from 'ethers';
import LOAN from '../../ABI/LOAN.json';
import LOANFAUCET from '../../ABI/LOANFAUCET.json';
import Moment from 'moment';

function Lending(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getLoanOffers = async () => {
		// const signer = provider.getUncheckedSigner()
		let contract = new ethers.Contract(
			process.env.REACT_APP_LOAN_ADDRESS,
			LOAN,
			provider
		);
		let newLoanOffers = [];

		try {
			let numOfLoans = await contract
				.counter()
				.then((res) => {
					return parseInt(res);
				})
				.catch((err) => {
					console.log(err);
				});

			for (let i = 1; i <= numOfLoans; i++) {
				let loanAddress = await contract
					.loans(i)
					.then((loanAddress) => {
						return loanAddress;
					})
					.catch((err) => {
						console.log('err', err);
					});
				console.log('Loan Exisits Here :', loanAddress);

				let loanContract = new ethers.Contract(
					loanAddress,
					LOANFAUCET,
					provider
				);

				// let loanData = await loanContract
				// 	.loanRequest()
				// 	.then((size) => {
				// 		return ethers.utils.formatUnits(size, '18');
				// 	})
				// 	.catch((err) => {
				// 		console.log(`err for ${i}`, err);
				// 	});

				let loanData = await loanContract
					.getLibVals()
					.then((res) => {
						console.log(ethers.utils.formatUnits(res[0], '18'));
						return ethers.utils.formatUnits(res[0], '18');
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let loanState = await loanContract
					.loanState()
					.then((state) => {
						console.log('state', parseInt(state));
						return parseInt(state);
					})
					.catch((err) => {
						console.log(err);
					});

				let minPriceRate = await loanContract
					.minPrice()
					.then((size) => {
						let price = (ethers.utils.formatUnits(size, '18') / loanData) * 100;
						return price.toFixed(3);
						//return price.toFixed(2);
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				console.log('Seceret Price: ', minPriceRate);

				let borrowerAddress = await loanContract
					.borrowerAddress()
					.then((addr) => {
						return addr;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let startDate = await loanContract
					.startDate()
					.then((num) => {
						console.log('startDate', num);

						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let maturityDate = await loanContract
					.maturityDate()
					.then((num) => {
						console.log('maturityDate', num);
						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				// console.log('here', wallet?.accounts[0]?.address);
				// console.log('here', borrowerAddress.toLowerCase());

				if (
					(borrowerAddress.toLowerCase() !== wallet?.accounts[0]?.address &&
						loanState === 1) ||
					(props.userType === 1 && loanState === 1)
				) {
					newLoanOffers.push({
						instrumentName: `${
							props.allUsers[borrowerAddress.toLowerCase()].userData.shortName
						}_FIXED_TBD_58569065${i}`,
						cusip: `58569065${i}`,
						issuerName: props.allUsers[borrowerAddress.toLowerCase()].userName,
						commitment: '',
						maxPrice: '',
						loanSize: loanData,
						price: ethers.utils.commify(minPriceRate),
						startDate: startDate,
						maturityDate: maturityDate,
						contract: loanAddress
					});
				}
			}
		} catch (e) {
			console.log(e);
		}

		setData(newLoanOffers);
		setIsLoading(false);
	};

	useEffect(() => {
		getLoanOffers();
	}, [props.refreshAll]);

	return (
		<>
			<Navbar
				userType={props.userType}
				setIsLoggedIn={props.setIsLoggedIn}
				refreshAll={props.refreshAll}
				setRefreshAll={props.setRefreshAll}
			/>
			<Container fixed>
				<Typography sx={{mb: 3, mt: 3}} variant='h5'>
					Lending
				</Typography>
				<Card>
					<Body
						currentUser={props.currentUser}
						setCurrentUser={props.setCurrentUser}
					/>
					<Toolbar
						sx={{
							pl: {sm: 2},
							pr: {xs: 1, sm: 1}
						}}
					>
						<Typography
							sx={{flex: '1 1 100%'}}
							variant='h6'
							id='tableTitle'
							component='div'
						>
							Loan Offers
						</Typography>
					</Toolbar>
					<Box sx={{minWidth: 1050, mb: 4}}>
						<TableContainer>
							<Table>
								<Header />
								<TableBody
									data={data}
									setData={setData}
									isLoading={isLoading}
								/>
							</Table>
						</TableContainer>
						<Pagination data={data} />
					</Box>

					{/* <Submit /> */}
				</Card>
			</Container>
		</>
	);
}

export default Lending;
