import React, {useEffect, useState, useContext} from 'react';
import {
	Box,
	Container,
	Typography,
	Grid,
	Button,
	TextField
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
//import * as yup from "yup";
import Submit from './Submit';
import {ethers} from 'ethers';

function Body(props) {
	useEffect(() => {
		props.setMaturityDate(
			Moment(addDays(props.startDate, props.term)).format('MM/DD/YYYY')
		);
	}, []);

	function addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	function addComa(value) {
		if (value.length < 2) {
			if ((value.charAt(0) >= '0' && value.charAt(0) <= '9') || value == '') {
				props.setQuantity(value);
			}
		} else {
			let numArr = value.split('');
			for (let i = 0; i < numArr.length; i++) {
				if (numArr[i] === ',') {
					numArr.splice(i, 1);
				}
			}
			let num = parseInt(numArr.join(''));
			props.setQuantity(num.toLocaleString());
		}
	}

	function handleStartDate(newValue) {
		props.setStartDate(newValue);

		console.log('term', props.term);
		props.setMaturityDate(
			Moment(addDays(newValue, props.term)).format('MM/DD/YYYY')
		);
	}

	const handleTermChange = (event) => {
		console.log('term', event.target.value);
		console.log('start date', props.startDate);
		props.setTerm(event.target.value);
		props.setMaturityDate(
			Moment(addDays(props.startDate, event.target.value)).format('MM/DD/YYYY')
		);
	};

	return (
		<Grid
			container
			rowSpacing={1}
			p={3}
			columnSpacing={{xs: 1, sm: 2, md: 3}}
			style={{backgroundColor: 'white'}}
		>
			<Grid item xs={6} p={2}>
				<TextField
					fullWidth
					label='Quantity $'
					name='quantity'
					variant='outlined'
					id='quantity'
					required
					defaultValue={0}
					value={props.quantity}
					onChange={(e) => addComa(e.target.value)}
				/>
			</Grid>
			<Grid item xs={6} p={2}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Stack spacing={3}>
						<DesktopDatePicker
							label='Start Date'
							name='startDate'
							id='startdate'
							value={props.startDate}
							minDate={new Date()}
							onChange={(newValue) => handleStartDate(newValue)}
							renderInput={(params) => <TextField {...params} />}
						/>
					</Stack>
				</LocalizationProvider>
			</Grid>
			<Grid item xs={6} p={2}>
				<TextField
					fullWidth
					id='issuerName'
					label='Issuer Name'
					//name="issuerName"
					aria-readonly={true}
					required
					variant='outlined'
					value={props.currentUser.userName}
				/>
			</Grid>
			<Grid item xs={6} p={2}>
				<TextField
					fullWidth
					label='Maturity Date'
					name='maturityDate'
					id='maturityDate'
					value={Moment(addDays(props.startDate, props.term)).format(
						'MM/DD/YYYY'
					)}
					variant='outlined'
					required
					//onChange={(e) => props.setMaturityDate(e.target.value)}
				/>
			</Grid>
			<Grid item xs={6} p={2}>
				<TextField
					fullWidth
					// helperText="Please enter the rate"
					label='Price'
					name='rate'
					id='rate'
					variant='outlined'
					required
					defaultValue={10.0}
					value={props.rate}
					onChange={(e) => props.setRate(e.target.value)}
				/>
			</Grid>

			<Grid item xs={6} p={2}>
				<TextField
					fullWidth
					label='Rate Type'
					name='rateType'
					id='rateType'
					onChange={(e) => props.setRateType(e.target.value)}
					value={props.rateType}
					required
					variant='outlined'
				/>
			</Grid>

			<Grid item xs={6} p={2}>
				<TextField
					select
					id='term'
					label='Term'
					name='term'
					value={props.term}
					onChange={handleTermChange}
					variant='outlined'
					fullWidth
					style={{textAlign: 'left'}}
				>
					<MenuItem value={1}>1 day</MenuItem>
					<MenuItem value={3}>3 days</MenuItem>
					<MenuItem value={5}>5 days</MenuItem>
					<MenuItem value={7}>7 days</MenuItem>
					<MenuItem value={30}>30 days</MenuItem>
					<MenuItem value={60}>60 days</MenuItem>
					<MenuItem value={90}>90 days</MenuItem>
					<MenuItem value={180}>180 days</MenuItem>
					<MenuItem value={270}>270 days</MenuItem>
					<MenuItem value={365}>365 days</MenuItem>
				</TextField>
			</Grid>

			<Grid item xs={6} p={2}>
				<TextField
					fullWidth
					label='Min Fill %'
					name='minfill'
					id='minfill'
					value={props.minFill}
					onChange={(e) => props.setMinFill(e.target.value)}
					variant='outlined'
				/>
			</Grid>

			<Grid item xs={12}>
				<Submit
					quantity={props.quantity}
					rate={props.rate}
					term={props.term}
					startDate={props.startDate}
					maturityDate={props.maturityDate}
					rateType={props.rateType}
					minFill={props.minFill}
				/>
			</Grid>
		</Grid>
	);
}

export default Body;
