import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'

function Header(props) {
    return (
        <TableHead>
            <TableRow>
                <TableCell align={'left'}>
                    <TableSortLabel>Instrument Name</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Cusip</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Issuer Name</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>size ($)</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Price</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Start Date</TableSortLabel>
                </TableCell>
                <TableCell align={'left'}>
                    <TableSortLabel>Maturity Date</TableSortLabel>
                </TableCell>
                {/* {
                    props.userType === 0 &&
                    <TableCell align={'left'}>
                        <TableSortLabel>
                            Actions
                        </TableSortLabel>
                    </TableCell>
                } */}
                {props.userType === 1 && (
                    <TableCell align={'left'}>
                        <TableSortLabel>Action</TableSortLabel>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}

export default Header
