import React from 'react';
import {TableRow, TableCell, Button} from '@mui/material';
import FAUCET from '../../ABI/FAUCET.json';
import {ethers} from 'ethers';
import {useWeb3Data} from '../../context/Web3Context';

function Item(props) {
	const {notify, provider} = useWeb3Data();

	const handleFaucet = async () => {
		const signer = provider.getUncheckedSigner();
		let contract = new ethers.Contract(
			process.env.REACT_APP_FAUCET_ADDRESS,
			FAUCET,
			signer
		);
		let amount = ethers.utils.parseEther('10000000000.0');

		try {
			const {hash} = await contract.estimateGas
				.getToken(amount, 'TUSDC', props.value.walletAddress)
				.then(async (suggestedGas) => {
					console.log('suggestedGas', suggestedGas);
					let gasLimit = parseInt(1.5 * suggestedGas);
					return await contract.getToken(
						amount,
						'TUSDC',
						props.value.walletAddress,
						{gasLimit}
					);
				})
				.catch((err) => {
					console.log(err);
					console.log(process.env.REACT_APP_FAUCET_ADDRESS);
				});

			txNotifications(hash);
		} catch (err) {
			console.log(err);
		}
	};

	const txNotifications = (hash) => {
		const {emitter} = notify.hash(hash);

		emitter.on('txPool', (transaction) => {
			return {
				onclick: () =>
					window.open(`https://goerli.etherscan.io/tx/${transaction.hash}`)
			};
		});
		emitter.on('txSent', console.log);
		emitter.on('txConfirmed', console.log);
		emitter.on('txSpeedUp', console.log);
		emitter.on('txCancel', console.log);
		emitter.on('txFailed', console.log);
	};

	return (
		<TableRow hover>
			<TableCell align='left'>{props.value.accountName}</TableCell>
			<TableCell align='left'>{props.value.walletAddress}</TableCell>
			<TableCell align='left'>
				${ethers.utils.commify(props.value.usdbBalance)}
			</TableCell>
			<TableCell align='left'>{props.value.amlorkycStatus}</TableCell>
			<TableCell align='left'>{props.value.investorStatus}</TableCell>

			<TableCell align='left'>
				<Button
					color='primary'
					variant='contained'
					type='submit'
					onClick={() => handleFaucet()}
				>
					Fund
				</Button>
			</TableCell>
		</TableRow>
	);
}

export default Item;
