import React from 'react';
import {TableRow, TableCell, Button} from '@mui/material';
import {format} from 'date-fns';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import {ethers} from 'ethers';
import {useWeb3Data} from '../../../context/Web3Context';

function Item(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	const handleCancel = async () => {
		const signer = provider.getUncheckedSigner();
		let loanContract = new ethers.Contract(
			props.value.contract,
			LOANFAUCET,
			signer
		);

		try {
			const {hash} = await loanContract.estimateGas
				.cancelBid()
				.then(async (suggestedGas) => {
					let gasLimit = parseInt(1.5 * suggestedGas);
					console.log('gasLimit', gasLimit);
					return await loanContract.cancelBid({gasLimit});
				})
				.catch((err) => {
					console.log(JSON.stringify(err.error.message));

					notify.notification({
						eventCode: 'none',
						type: 'error',
						message: JSON.stringify(err.error.message),
						autoDismiss: 8000
					});
				});

			txNotifications(hash);
		} catch (err) {
			console.log(err);
		}
	};

	const txNotifications = (hash) => {
		const {emitter} = notify.hash(hash);

		emitter.on('txPool', (transaction) => {
			return {
				onclick: () =>
					window.open(`https://goerli.etherscan.io/tx/${transaction.hash}`)
			};
		});
		emitter.on('txSent', console.log);
		emitter.on('txConfirmed', console.log);
		emitter.on('txSpeedUp', console.log);
		emitter.on('txCancel', console.log);
		emitter.on('txFailed', console.log);
	};

	return (
		<TableRow hover>
			<TableCell align='left'>{props.value.instrumentName}</TableCell>
			<TableCell align='left'>{props.value.cusip}</TableCell>
			<TableCell align='left'>{props.value.lenderName}</TableCell>
			<TableCell align='left'>{props.value.loanSize}</TableCell>
			<TableCell align='left'>{props.value.price}</TableCell>
			<TableCell align='left'>{props.value.startDate}</TableCell>
			<TableCell align='left'>{props.value.maturityDate}</TableCell>
			{props.userType === 0 && (
				<TableCell align='left'>
					<Button
						color='primary'
						variant='contained'
						type='submit'
						onClick={() => handleCancel()}
					>
						Cancel
					</Button>
				</TableCell>
			)}
		</TableRow>
	);
}

export default Item;
