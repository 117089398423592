import React, {useEffect, useState} from 'react';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import {
	Box,
	Table,
	TableContainer,
	Typography,
	Paper,
	Toolbar,
	Container
} from '@mui/material';
// import TOKEN from '../../ABI/TOKEN.json'
import LOAN from '../../../ABI/LOAN.json';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import {useWeb3Data} from '../../../context/Web3Context';
import {ethers} from 'ethers';
import Moment from 'moment';

const fakeData = [
	{
		instrumentName: 'COIN_FIXEDTBD_180D_05102022',
		cusip: 585690658,
		issuerName: 'Coinbase',
		loanSize: '$625,000,000.00 ',
		price: 95.55,
		startDate: 44691,
		maturityDate: 44871
	},
	{
		instrumentName: 'AAPL_FIXEDTBD_90D_06012022',
		cusip: 399605676,
		issuerName: 'Apple',
		loanSize: '$1,200,000,000.00 ',
		price: 98.75,
		startDate: 44713,
		maturityDate: 44803
	},
	{
		instrumentName: 'GOOG_FIXEDTBD_270D_05052022',
		cusip: 502737775,
		issuerName: 'Google',
		loanSize: '$745,000,000.00 ',
		price: 97.35,
		startDate: 44686,
		maturityDate: 44956
	}
];

function LoanOffers(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();

	// console.log('LoanOffers props', props.userType)
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);

	const getLoanOffers = async () => {
		// const signer = provider.getUncheckedSigner()
		let contract = new ethers.Contract(
			process.env.REACT_APP_LOAN_ADDRESS,
			LOAN,
			provider
		);
		let newLoanOffers = [];

		try {
			let numOfLoans = await contract
				.counter()
				.then((res) => {
					console.log('current counter ', res);
					return parseInt(res);
				})
				.catch((err) => {
					console.log(err);
				});

			for (let i = 1; i <= numOfLoans; i++) {
				let loanAddress = await contract
					.loans(i)
					.then((loanAddress) => {
						return loanAddress;
					})
					.catch((err) => {
						console.log('err', err);
					});
				// console.log(loanAddress)

				let loanContract = new ethers.Contract(
					loanAddress,
					LOANFAUCET,
					provider
				);

				let loanData = await loanContract
					.getLibVals()
					.then((res) => {
						console.log(ethers.utils.formatUnits(res[0], '18'));
						return ethers.utils.formatUnits(res[0], '18');
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				console.log(loanData);

				// function getLibVals() public view returns(uint256, uint256, uint256, uint256, uint256) {
				// 	return(winningBidRates._loanRequest, winningBidRates._loanRate, winningBidRates._loanObligation, winningBidRates._period, winningBidRates._loanAmt);
				// }

				// let loanData = await loanContract
				// 	.loanRequest()
				// 	.then((size) => {
				// 		return ethers.utils.formatUnits(size, '18');
				// 	})
				// 	.catch((err) => {
				// 		console.log(`err for ${i}`, err);
				// 	});

				let minPriceRate = await loanContract
					.minPrice()
					.then((size) => {
						let price = (ethers.utils.formatUnits(size, '18') / loanData) * 100;
						// console.log(price)
						return price.toFixed(3);
						// return ethers.utils.formatUnits(size, '18')
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let borrowerAddress = await loanContract
					.borrowerAddress()
					.then((addr) => {
						return addr.toLowerCase();
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				// console.log(props.allUsers[borrowerAddress.toLowerCase()])
				// console.log(borrowerAddress)

				let status = await loanContract
					.loanObligation()
					.then((status) => {
						return parseInt(status);
					})
					.catch((err) => {
						console.log(err);
					});

				let startDate = await loanContract
					.startDate()
					.then((num) => {
						console.log('startDate', num);

						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let maturityDate = await loanContract
					.maturityDate()
					.then((num) => {
						console.log('maturityDate', num);
						return num;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				let loanState = await loanContract
					.loanState()
					.then((state) => {
						return parseInt(state);
					})
					.catch((err) => {
						console.log(err);
					});

				if (
					(borrowerAddress.toLowerCase() === wallet?.accounts[0]?.address &&
						status === 0 &&
						loanState === 1) ||
					(props.userType === 1 && status === 0 && loanState === 1)
				) {
					console.log(`58569065${i} is ${status}`);
					newLoanOffers.push({
						instrumentName: `${
							props.allUsers[borrowerAddress.toLowerCase()].userData.shortName
						}_FIXED_TBD_58569065${i}`,
						cusip: `58569065${i}`,
						issuerName: props.allUsers[borrowerAddress.toLowerCase()].userName,
						loanSize: '$' + ethers.utils.commify(loanData),
						price: minPriceRate,
						startDate: startDate,
						maturityDate: maturityDate,
						contract: loanAddress
					});
				} else {
					console.log(`58569065${i} is ${status} ${loanState}`);
				}
			}
		} catch (e) {
			console.log(e);
		}

		setData(newLoanOffers);
		setIsLoading(false);
	};

	useEffect(() => {
		getLoanOffers();
	}, [props.userType, props.allUsers, props.refreshAll]);

	return (
		<Container maxWidth={false}>
			<Box sx={{mt: 3}}>
				<Box sx={{minWidth: 1050}}>
					<Paper sx={{width: '100%', mb: 2}}>
						<Toolbar
							sx={{
								pl: {sm: 2},
								pr: {xs: 1, sm: 1}
							}}
						>
							<Typography
								sx={{flex: '1 1 100%'}}
								variant='h6'
								id='tableTitle'
								component='div'
							>
								Loan Offers
							</Typography>
						</Toolbar>
						<TableContainer>
							<Table>
								<Header setData={setData} userType={props.userType} />
								<Body
									data={data}
									userType={props.userType}
									setSelectedLoanDetailsItem={props.setSelectedLoanDetailsItem}
									setRefreshAll={props.setRefreshAll}
									refreshAll={props.refreshAll}
									isLoading={isLoading}
								/>
							</Table>
						</TableContainer>
						<Pagination data={data} />
					</Paper>
				</Box>
			</Box>
		</Container>
	);
}

export default LoanOffers;
