import React from 'react'
import Body from './Body'
import UserPortfolioNavigation from './UserPortfolioNavigation'
import { Box, Container, Grid, Typography } from '@mui/material'

function UserPortfolio(props) {
    return (
        <Container maxWidth="lg">
            <Typography sx={{ mb: 3 }} variant="h6">
                User Portfolio
            </Typography>
            <Body currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} refreshAll={props.refreshAll} />
        </Container>
    )
}

export default UserPortfolio
