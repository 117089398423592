import React, {useEffect, useState} from 'react';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import {
	Box,
	Table,
	TableContainer,
	Typography,
	Paper,
	Toolbar,
	Container
} from '@mui/material';
import {ethers} from 'ethers';
import LOANFAUCET from '../../../ABI/LOANFAUCET.json';
import {useWeb3Data} from '../../../context/Web3Context';

const fakeData = [
	{
		instrumentName: 'AAPL_FIXED97.43_90D_03062022',
		cusip: 608762387,
		lenderName: 'Goldman Sachs',
		loanSize: '$325,000,000.00 ',
		price: 97.43,
		startDate: 44626,
		maturityDate: 44716
	},
	{
		instrumentName: 'AAPL_FIXED97.43_90D_03062022',
		cusip: 608762387,
		lenderName: 'Breavan Howard',
		loanSize: '$162,500,000.00 ',
		price: 97.43,
		startDate: 44626,
		maturityDate: 44716
	},
	{
		instrumentName: 'AAPL_FIXED97.43_90D_03062022',
		cusip: 608762387,
		lenderName: 'JP Morgan',
		loanSize: '$162,500,000.00 ',
		price: 97.43,
		startDate: 44626,
		maturityDate: 44716
	},
	{
		instrumentName: 'GOOG_FIXED96.23_180D_04152022',
		cusip: 862902374,
		lenderName: 'Goldman Sachs',
		loanSize: '$170,000,000.00 ',
		price: 96.23,
		startDate: 44666,
		maturityDate: 44846
	},
	{
		instrumentName: 'GOOG_FIXED96.23_180D_04152022',
		cusip: 862902374,
		lenderName: 'Breavan Howard',
		loanSize: '$127,500,000.00 ',
		price: 96.23,
		startDate: 44666,
		maturityDate: 44846
	},
	{
		instrumentName: 'GOOG_FIXED96.23_180D_04152022',
		cusip: 862902374,
		lenderName: 'JP Morgan',
		loanSize: '$127,500,000.00 ',
		price: 96.23,
		startDate: 44666,
		maturityDate: 44846
	},
	{
		instrumentName: 'GOOG_FIXED98.65_90D_03122022',
		cusip: 846962463,
		lenderName: 'Goldman Sachs',
		loanSize: '$120,000,000.00 ',
		price: 98.65,
		startDate: 44632,
		maturityDate: 44722
	},
	{
		instrumentName: 'GOOG_FIXED98.65_90D_03122022',
		cusip: 846962463,
		lenderName: 'Breavan Howard',
		loanSize: '$90,000,000.00 ',
		price: 98.65,
		startDate: 44632,
		maturityDate: 44722
	},
	{
		instrumentName: 'GOOG_FIXED98.65_90D_03122022',
		cusip: 846962463,
		lenderName: 'JP Morgan',
		loanSize: '$90,000,000.00 ',
		price: 98.65,
		startDate: 44632,
		maturityDate: 44722
	},
	{
		instrumentName: 'COIN_FIXED95.55_270D_04082022',
		cusip: 332901994,
		lenderName: 'Goldman Sachs',
		loanSize: '$153,000,000.00 ',
		price: 95.55,
		startDate: 44659,
		maturityDate: 44929
	},
	{
		instrumentName: 'COIN_FIXED95.55_270D_04082022',
		cusip: 332901994,
		lenderName: 'Breavan Howard',
		loanSize: '$153,000,000.00 ',
		price: 95.55,
		startDate: 44659,
		maturityDate: 44929
	},
	{
		instrumentName: 'COIN_FIXED95.55_270D_04082022',
		cusip: 332901994,
		lenderName: 'JP Morgan',
		loanSize: '$306,000,000.00',
		price: 95.55,
		startDate: 44659,
		maturityDate: 44929
	},
	{
		instrumentName: 'GS_FIXED95.75_180D_04172022',
		cusip: 704068253,
		lenderName: 'Breavan Howard',
		loanSize: '$220,000,000.00 ',
		price: 95.75,
		startDate: 44668,
		maturityDate: 44848
	},
	{
		instrumentName: 'GS_FIXED95.75_180D_04172022',
		cusip: 704068253,
		lenderName: 'JP Morgan',
		loanSize: '$220,000,000.00',
		price: 95.75,
		startDate: 44668,
		maturityDate: 44848
	}
];

function DebtInvestments(props) {
	const {
		web3Onboard,
		notify,
		connectedWallets,
		wallet,
		connect,
		provider,
		disconnect,
		setChain,
		connectedChain
	} = useWeb3Data();
	// const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([]);

	const getActiveLoanInvestments = async () => {
		let newActiveLoanInvestments = [];
		//setData([])

		console.log('yyyyyyyyyyyyyyyyyLS: ', props.activeLoanInvestments.length);

		for (let i = 0; i < props.activeLoanInvestments.length; i++) {
			// console.log('here boi 2', props.activeLoanInvestments[i])
			// console.log(props.activeLoanInvestments[i].contract)
			let loanContract = new ethers.Contract(
				props.activeLoanInvestments[i].contract,
				LOANFAUCET,
				provider
			);

			try {
				let winners = await loanContract
					.getWinningBids()
					.then((arr) => {
						return arr;
					})
					.catch((err) => {
						console.log(`err for ${i}`, err);
					});

				console.log('yyyyyyyyyyyyyyyyyLS: ', winners);

				for (let j = 0; j < winners.length; j++) {
					let myBids = await loanContract
						.getBid(winners[j])
						.then((bid) => {
							let name = {...bid};
							name.bidSize = ethers.utils.formatUnits(bid[0], 18);
							name.bidRate = ethers.utils.formatUnits(bid[1], 18);
							name.loanSize = bid[3];
							return name;
						})
						.catch((err) => {
							console.log(`err for ${j}`, err);
						});

					console.log(
						'********************* ',
						props.activeLoanInvestments[i].price
					);

					let loanState = await loanContract
						.loanState()
						.then((state) => {
							// console.log(parseInt(state));
							return parseInt(state);
						})
						.catch((err) => {
							console.log(err);
						});

					let startDate = await loanContract
						.startDate()
						.then((num) => {
							console.log('startDate', num);

							return num;
						})
						.catch((err) => {
							console.log(`err for ${i}`, err);
						});

					let maturityDate = await loanContract
						.maturityDate()
						.then((num) => {
							console.log('maturityDate', num);
							return num;
						})
						.catch((err) => {
							console.log(`err for ${i}`, err);
						});

					console.log('yyyyyyyyyyyyyyyyyLS: ', loanState);

					if (
						(parseInt(myBids.bidPrice) !== 0 &&
							myBids[6] === true &&
							loanState === 2) ||
						loanState === 3
					) {
						console.log('yyyyyyyyyyyyyyyyy: ', winners[j].toLowerCase());
						console.log('yyyyyyyyyyyyyyyyy: ', wallet?.accounts[0]?.address);
						if (
							winners[j].toLowerCase() === wallet?.accounts[0]?.address ||
							props.userType === 1
						) {
							// console.log(props.userType);
							console.log(
								props.activeLoanInvestments[i].cusip +
									' is in loanState : ' +
									loanState
							);

							let formatLoan = ethers.utils.formatUnits(myBids.loanSize, 18);
							let newLoanSize = parseInt(formatLoan).toFixed(2).toString();

							console.log('#####################', newLoanSize);

							if (newLoanSize > 0) {
								newActiveLoanInvestments.push({
									instrumentName:
										props.activeLoanInvestments[i].intstrumentName,
									cusip: props.activeLoanInvestments[i].cusip,
									lenderName: props.allUsers[winners[j].toLowerCase()].userName,
									loanSize: '$' + ethers.utils.commify(newLoanSize),
									price: props.activeLoanInvestments[i].price,
									startDate: startDate,
									maturityDate: maturityDate,
									contract: props.activeLoanInvestments[i]
								});
							}
						}
					}
				}
			} catch (e) {
				console.log(e);
			}
		}
		setData(newActiveLoanInvestments);
	};

	useEffect(() => {
		setData([]);
		getActiveLoanInvestments();
	}, [props.activeLoanInvestments, props.refreshAll]);

	return (
		<Container maxWidth={false}>
			<Box sx={{mt: 3}}>
				<Box sx={{minWidth: 1050}}>
					<Paper sx={{width: '100%', mb: 2}}>
						<Toolbar
							sx={{
								pl: {sm: 2},
								pr: {xs: 1, sm: 1}
							}}
						>
							<Typography
								sx={{flex: '1 1 100%'}}
								variant='h6'
								id='tableTitle'
								component='div'
							>
								Active Loan Investments
							</Typography>
						</Toolbar>
						<TableContainer>
							<Table>
								<Header setData={setData} />
								<Body data={data} isLoading={props.isLoading} />
							</Table>
						</TableContainer>
						<Pagination data={data} />
					</Paper>
				</Box>
			</Box>
		</Container>
	);
}

export default DebtInvestments;
