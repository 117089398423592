import React from 'react'
import Item from './Item'
import { TableBody, TableRow, TableCell } from '@mui/material'

function Body(props) {
    return (
        <TableBody>
            {
                props.isLoading ?
                    <TableRow>
                        <TableCell>
                            Loading...
                        </TableCell>
                    </TableRow>
                :
                    props.data.map((value, index) => (
                        <Item key={index} index={index} value={value} userType={props.userType} data={props.data} setData={props.setData} />
                    ))
            }
        </TableBody>
    )
}

export default Body
