import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, TextField } from '@mui/material'
import UserPortfolioNavigation from './UserPortfolioNavigation'
import { ethers } from 'ethers'
import TOKEN from '../../../ABI/TOKEN.json'
import { useWeb3Data } from '../../../context/Web3Context'
import { RefreshOutlined } from '@mui/icons-material'

function Body(props) {
    const { web3Onboard, notify, connectedWallets, wallet, connect, provider, disconnect, setChain, connectedChain } = useWeb3Data()

    const [balance, setBalance] = useState(props.currentUser.userData.usdbBalance)
    const reloadBalance = async (index) => {
        let usdbContract = new ethers.Contract(process.env.REACT_APP_TOKEN_ADDRESS, TOKEN, provider)
        const usdbBalance = await usdbContract.balanceOf(wallet?.accounts[0]?.address)
        let newBalance = ethers.utils.formatUnits(usdbBalance, 18)
        newBalance = parseInt(newBalance).toFixed(2).toString()
        setBalance(newBalance)

        let newCurUser = { ...props.currentUser }
        newCurUser.userData.usdbBalance = balance
        props.setCurrentUser(newCurUser)
    }

    useEffect(() => {
        reloadBalance()
    }, [props.refreshAll])

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <TextField fullWidth aria-readonly={true} label="Account" value={props.currentUser.userName} variant="outlined" />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField fullWidth aria-readonly={true} label="AML/KYC Status" value={props.currentUser.userData.amlorkycStatus} variant="outlined" />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            aria-readonly={true}
                            label="USDB Balance"
                            value={ethers.utils.commify(balance)}
                            InputProps={{ endAdornment: <RefreshOutlined sx={{cursor: 'pointer'}} onClick={() => reloadBalance()} /> }}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField fullWidth aria-readonly={true} label="Investor Qualification" value={props.currentUser.userData.investorStatus} variant="outlined" />
                    </Grid>
                </Grid>

                <UserPortfolioNavigation currentUser={props.currentUser} />
            </CardContent>
        </Card>
    )
}

export default Body
