import React, {useEffect, useState} from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Tooltip
} from '@mui/material';
import {Link} from 'react-router-dom';

function UserPortfolioNavigation(props) {
	const buttonStyles = {color: 'white', textDecoration: 'none'};
	// console.log(props.currentUser.userData.amlorkycStatus)

	return (
		<Box sx={{mt: 3}}>
			{props.currentUser.userData.amlorkycStatus !== 'High Risk' ? (
				<div>
					<Link style={buttonStyles} to={'/loan-request'}>
						<Button color='primary' variant='contained' sx={{margin: 3}}>
							Make New Loan Request
						</Button>
					</Link>
					<Link style={buttonStyles} to={'/lending'}>
						<Button color='primary' variant='contained' sx={{margin: 3}}>
							Place Bids on Open Loans
						</Button>
					</Link>
				</div>
			) : (
				<div>
					<Tooltip
						title='AML/KYC risk too high! Contact One Bridge.'
						placement='bottom'
					>
						<Button color='error' variant='contained' sx={{margin: 3}}>
							Make New Loan Request
						</Button>
					</Tooltip>
					<Tooltip
						title='AML/KYC risk too high! Contact One Bridge.'
						placement='bottom'
					>
						<Button color='error' variant='contained' sx={{margin: 3}}>
							Place Bids on Open Loans
						</Button>
					</Tooltip>
				</div>
			)}
		</Box>
	);
}

export default UserPortfolioNavigation;
